import { useEffect, useLayoutEffect } from 'react'
import { useQueryClient } from 'react-query'
import { usePrevious } from '../libs/hooks'
import { useLocation, useHistory } from 'react-router-dom'
import EventBus from '../helpers/EventBus'
import AnalyticsHelper from '../helpers/GoogleAnalyticsHelper'

// detect changes in location - use useLayoutEffect to time before other changes
// called too late sometimes and overrides
export const useLocationChange = (action) => {
  const location = useLocation()
  const prevLocation = usePrevious(location)
  useLayoutEffect(() => { 
    action(location, prevLocation) 
  }, [location])
}
// TODO CONSOLIDATE
// listen for changes to location problem is changes after some components have already called
// try history listen or  useLayoutEffect
// NEED HISTORY LISTEN
export default function HistoryListener({resetCrumb}) {
  const ga = new AnalyticsHelper()
  const history = useHistory();
  const queryClient = useQueryClient()
  //redirect option?
  //clear queries
  useEffect(() => {
    EventBus.on("logout", () => {
      //window.history.replaceState({}, document.title)
      // history.replace({ state: null })
      queryClient.clear()
      // console.log("Logout event")
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      //in this context Replace means it is already set with different key only
      //versus prev state to current
      if (action !== "REPLACE") {
        resetCrumb()
        ga.getClient().pageview(location.pathname)
        // //Hubspot array
			var _hsq = window._hsq = window._hsq || [];
			_hsq.push(['setPath', window.location.pathname]);
			_hsq.push(['trackPageView']);
      }
      
      console.log(`The current URL is ${location.pathname}`)
      console.log(`The last navigation action was ${action}`)
    })
    return () => {
      unlisten();
    };
  }, [history])

  /* useLocationChange((location, prevLocation) => { 
    const isSameLocation = prevLocation ? location.pathname === prevLocation.pathname : false
    console.log('LOCATION changed from', prevLocation, 'to', location, 'isSame', isSameLocation) 
    if (!isSameLocation) {
      // resetCrumb()
      ga.getClient().pageview(location.pathname)
    }
  }) */

  return null
}