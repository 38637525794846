import React from 'react'
import { connect } from 'react-redux'
import { addNotification as notify } from 'reapop'
import { getUncOrders, getPartners, getGroups, setUserActive } from '../actions'

import { useMemoizedCallback } from '../libs/hooks'
import AuthenticatedWrapper from './AuthenticatedWrapper'

//handle updates when app authenticated and other changes
//wrapper around auth render props
//could be HOC?

//MAKE THIS FORM WITH OUT CHILDREN - just load and change via the callbacks?
//could return loading and checked?

function AuthenticatedUpdater(props) {
	const {
		//userData,
		partnerGroups,
		fetchingGroups,
		//fetchingUser,
		getGroups,
		getPartners,
		partners,
		//partnerContext,
		fetchingPartners,
		getUncOrders,
		unconfirmedOrders,
		fetchingOrders,
		notify,
		setUserActive,
		children
	} = props
	//const [checkingLoad, setCheckingLoad] = useState(false)
	//const [allChecked, setAllChecked] = useState(false)

	//extended form that sends userData and partnerContext?
	const onAuthentication = useMemoizedCallback(
		(isAuthenticated, userData, partnerContext) => {
			console.log('ON AUTH CHANGE', isAuthenticated)
			if (isAuthenticated) {
				loadAuthenticatedItems(true, { userData, partnerContext })
				setUserActive(true) //used to prevent public route changing before complete
			}
		}
	)

	const onPartnerContext = useMemoizedCallback(partnerContext => {
		getUncOrders(false, partnerContext)
		console.log('ON PARTNER CONTEXT Chenge', partnerContext)
	})

	const loadAuthenticatedItems = useMemoizedCallback((retry = true, params) => {
		const { userData, partnerContext } = params
		if (userData.is_supplier !== 1) {
			//setCheckingLoad(true)
			if (partnerGroups === null && fetchingGroups === false) {
				getGroups()
			}
			if (partners === null && fetchingPartners === false) {
				const setFirstPartner =
					['Operations', 'Developer'].indexOf(userData.role_name) >= 0
						? false
						: true
				getPartners(setFirstPartner)
			}
			if (unconfirmedOrders === null && fetchingOrders === false) {
				getUncOrders(false, partnerContext).then(args => {
					console.log('auth items complete', args)
					if (args) {
						//setAllChecked(true)
						//setCheckingLoad(false)
						//setUserActive(true)
					} else {
						if (retry) {
							loadAuthenticatedItems(false)
						} else {
							//console.log("error after retry")

							//setAllChecked(true)
							//setCheckingLoad(false)
							notify({
								title: `Cart Orders`,
								message: `Error loading active orders`,
								status: 'error',
								position: 'tr',
								dismissible: true,
								dismissAfter: 5000
							})
						}
					}
				})
			}
		}
	})

	return (
		<AuthenticatedWrapper
			onAuthenticationChange={onAuthentication}
			onPartnerChange={onPartnerContext}>
			{({ isAuthenticated, isAuthPending }) =>
				children({
					isAuthenticated,
					//userData,
					isAuthPending,
				})
			}
		</AuthenticatedWrapper>
	)
}

const mapDispatchToProps = {
	notify,
	getGroups,
	getUncOrders,
	getPartners,
	setUserActive
}

const mapStateToProps = state => {
	return {
		partnerGroups: state.partnerGroups.groups,
		fetchingGroups: state.partnerGroups.fetchingGroups,
		partnerContext: state.partnerContext,
		partners: state.partners.partners,
		fetchingPartners: state.partners.fetchingPartners,
		unconfirmedOrders: state.unconfirmedOrders.orders,
		fetchingOrders: state.unconfirmedOrders.fetching
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AuthenticatedUpdater)
