import React, {Fragment, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { fetchRecords } from 'helpers/Helper'
import { API_URL } from 'helpers/Constants'
import { navLinkCls, navBadgeCls } from '../helpers'


const SupplierUpdatesForOrder = props => {
	const [updateCount, setUpdateCount] = useState(0)
    const fetchSupplierUpdateCount = async () => {
		let options = {
			url: `${API_URL}/supplier/getNotifications/orderStore`
		}
		let res = await fetchRecords(options)
		if (res !== null) {
			 setUpdateCount(res.data.update_count)
		}
	}

	useEffect(() => {
		fetchSupplierUpdateCount()
	});

	return (
		<Fragment>
			<Link to="/admin/supplier/updatesorder" className={navLinkCls}>
				{updateCount > 0 && (
					<span className={navBadgeCls}>
							{updateCount}
						</span>
				)}
				<span className={`fa fa-clipboard-check fa-lg`} />
			</Link>
		</Fragment>
	)
}

export default SupplierUpdatesForOrder
