import {lazy} from 'react'
import EmptyViewWrapper from '../layouts/landing/EmptyViewWrapper'

//const Dashboard = lazy(() => import('../dashboard/DashBoard'))
//const ProductsView = lazy(() => import('../views/products/ProductsView'))
//const InvoicesView = lazy(() => import('../views/invoices/InvoicesView'))

const mainRoutes = [
	{
		key: 'account-status',
		path: '/account-status',
		component: lazy(() => import('../auth/account-status')),
		exact: true,
		//layout: EmptyViewWrapper
	},
	{
		key: 'dashboard-view',
		path: '/',
		component: lazy(() => import('../dashboard')),
		exact: true
	},
	{
		key: 'products-view',
		path: '/products',
		component: lazy(() => import('../views/products')),
		exact: true
	},
	{
		key: 'product-edit',
		path: '/product/edit/:hashid?',
		component: lazy(() => import('../product/edit')),
		//roles: ['Operations', 'Developer', 'Vendor Outreach', 'Field Manager']
	},
	{
		key: 'product-display-edit',
		path: '/product/display/edit/:hashid?',
		component: lazy(() => import('../product/display/edit')),
		//roles: ['Operations', 'Developer', 'Vendor Outreach', 'Field Manager']
	},
	{
		key: 'product-profile',
		path:"/product/view/:hashid?",
		component: lazy(() => import('../product/profile')),
		exact: true,
		redirectTo: {
			path: '/start/product/view/',
			paramsMatch: 'hashid'
		}
	},
	{
		key: 'vendors-view',
		path: '/vendors',
		component: lazy(() => import('../views/vendors')),
		exact: true
	},
	{
		key: 'user-sample-requests-view',
		path: '/samplerequests',
		component: lazy(() => import('../views/sample-requests/UserSampleRequests')),
		exact: true
	},
	{
		key: 'barcode-scan-view',
		path: '/barcode-scan',
		component: lazy(() => import('../views/barcode/BarcodeScan')),
		exact: true
	},
	{
		key: 'vendor-edit',
		path: '/vendor/edit/:vendorHash?',
		component: lazy(() => import('../vendor/edit')),
	},
	{
		key: 'vendor-profile',
		path:"/vendor/view/:vendorHash?/:partnerHash?",
		component: lazy(() => import('../vendor')),
		exact: true,
		redirectTo: {
			path: '/start/vendor/view/',
			paramsMatch: 'vendorHash'
		}
	},
	{
		key: 'invoices-view',
		path: '/invoices',
		component: lazy(() => import('../views/invoices')),
		exact: true
	},
	{
		key: 'orders-view',
		path: '/orders/:filter?',
		component: lazy(() => import('../views/orders/orders')),
		exact: true
	},
	{
		key: 'order-view',
		path: '/order/view/:store/:type/:vendor/:dateRequired?',
		component: lazy(() => import('../order/view')),
		exact: true
	},
	{
		key: 'order-guide',
		path: '/order/start/:storeHash?/:straightToCart?',
		component: lazy(() => import('../views/orders/order/start')),
		//not used? checks permissions instead?
		/* roles: [
			'Operations',
			'Developer',
			'Vendor Outreach',
			'District',
			'Store',
			'SFP Client',
			'Specialty Distributor',
			'Field Manager',
			'Limited Category Manager'
		], */
		exact: true
	},
	{
		key: 'reports-view',
		path: '/reports',
		component: lazy(() => import('../reports')),
		exact: true
	},
	{
		key: 'credits-view',
		path: '/credits',
		component: lazy(() => import('../credits')),
		exact: true
	},
	{
		key: 'credit-edit',
		path: '/credit/:hashid',
		component: lazy(() => import('../credit')),
		exact: true
	},
	{
		key: 'stores-manager',
		path: '/stores/manager',
		component: lazy(() => import('../views/stores/manager')),
		exact: true
	},
	{
		key: 'support-faqs',
		path: '/support/faq',
		redirectTo: {
			path: '/start/support/faq/',
		},
		component: lazy(() => import('../support/Faq')),
		exact: true
	},
	{
		key: 'support-tutorial',
		path: '/support/tutorial',
		component: lazy(() => import('../support/Tutorial')),
		exact: true
	},
	{
		key: 'style-guide-view',
		path: '/style-guide',
		component: lazy(() => import('../views/styleGuide')),
		exact: true
	},
	{
		key: 'stripe-return',
		path: '/stripe-return',
		component: lazy(() => import('../stripe')),
		exact: true
	},
	{
		key: 'stripe-reauth',
		path: '/stripe-reauth',
		component: lazy(() => import('../stripe/StripeReauth')),
		exact: true
	},
	{
		key: 'admin-router',
		path: '/admin',
		component: lazy(() => import('../views/admin')),
	},
	{
		key: 'supplier-router',
		path: '/supplier',
		component: lazy(() => import('../views/supplier')),
	},


]

export default mainRoutes
