import { SET_CRUMB, RESET_CRUMB } from 'actions/types'

const INITIAL_STATE = []
//REMOVED RELIANCE ON CONNECTED ROUTER
//change of same path will set empty as key changes

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_CRUMB:
			return action.payload
		case RESET_CRUMB:
				return INITIAL_STATE
		/* case LOCATION_CHANGE:
			const isSameState = action.payload.location.pathname === store.getState().router.location.pathname
			//console.log("location change", action.payload, state, store.getState(), isSameState)
			return isSameState ? state : INITIAL_STATE */
		default:
			return state
	}
}
