import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import MobileNav from './MobileNav'

const Component = compose(
	connect(state => {
		return {
			user: state.user.userData,
			partners: state.partners.partners
		}
	}),
	branch(
		() => true,
		renderComponent(MobileNav),
		renderNothing
	)
)(MobileNav)

export default Component
