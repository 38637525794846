import React from 'react'
import clsx from 'clsx'

const baseEmptyViewCls = 'empty-view'

// basis for empty view auth pages with back ground
// need sub domain?

export default function EmptyViewWrapper({ children, className }) {
	const retCls = clsx(baseEmptyViewCls, className)
	return <div className={retCls}>{children}</div>
}
