import React, { Fragment } from 'react'
export const subdomainDetails = {
	sfa: {
		Navclass: 'sfa',
		topNavClass: 'sfa sfa-top-nav',
		bottomNavClass: 'sfa-bottom-nav',
		contentClass: 'sfa-content',
		modalClass: 'sfa-modal',
		//sidebarImagePath: '/assets/images/SFA_logo_primary.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/sfa_logo.png"
					width="70"
					className="pt-0 pe-2 pb-0"
					alt="Specialty Food Association"
				/>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/SFA_favicon.png',
		marketplaceName: 'SFA'
	},
	chex: {
		Navclass: 'chex text-white',
		topNavClass: 'chex',
		bottomNavClass: 'chex',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/chex_finer_foods.png',
		logo: (
			<Fragment>
				<img
					src="infinite-aisle-logo-transparent-white.png"
					width="180"
					className="pt-0 pe-0 pb-0"
					alt="Infinite Aisle"
				/>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/SFA_favicon.png',
		marketplaceName: 'Infinite Aisle'
	},
	csspecialty: {
		Navclass: 'csspecialty',
		topNavClass: 'csspecialty',
		bottomNavClass: 'csspecialty',
		contentClass: 'csspecialty-content',
		modalClass: 'csspecialty-modal',
		sidebarImagePath: '/assets/images/cs-specialty-logo.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/cs-specialty-logo-wt.png"
					width="180"
					className="pt-2 pr-0 pb-0"
					alt="C&S Specialty Market"
				/>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/cs-specialty-favicon.png',
		marketplaceName: 'C&S Specialty Market'
	},
	duo: {
		Navclass: 'duo',
		topNavClass: 'duo',
		bottomNavClass: 'duo',
		contentClass: 'duo',
		modalClass: '',
		sidebarImagePath: '/assets/images/duo-logo.jpeg',
		logo: '',
		favIcon: '',
		marketplaceName: 'Duo Marketplace'
	},
	emerge: {
		Navclass: 'emerge',
		topNavClass: 'emerge',
		bottomNavClass: 'emerge',
		contentClass: 'emerge-content',
		modalClass: 'emerge-modal',
		sidebarImagePath: '/assets/images/Emerge.png',
	},
	fdm: {
		Navclass: 'fdm',
		topNavClass: 'fdm',
		bottomNavClass: 'fdm',
		contentClass: 'fdm-content',
		modalClass: 'fdm-modal',
		sidebarImagePath: '/assets/images/fdm-logo.png',
		favIcon: '%PUBLIC_URL%/fdm-favicon.png',
		marketplaceName: 'FDM'
	},
	agbr: {
		Navclass: 'agbr text-white',
		topNavClass: 'agbr',
		bottomNavClass: 'agbr',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/sfp_logo_no_words.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/agbr_white.png"
					className="pt-0 pe-2 pb-0"
					width="100"
					alt="Associated Grocers"
				/>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/agbr-favicon.jpeg',
		marketplaceName: 'Associated Grocers'
	},
	kehe: {
		Navclass: '',
		topNavClass: '',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/sfp_logo_no_words.png',
		logo: (
			<Fragment>
				<span className="title mt-0">Kehe</span>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/kehe-favicon.png',
		marketplaceName: 'KEHE Marketplace'
	},
	spartannash: {
		Navclass: 'spartannash',
		topNavClass: 'spartannash',
		bottomNavClass: 'spartannash',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/sfp_logo_no_words.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/spartan_nash_logo-transparent.png"
					alt="SpartanNash Specialty Direct"
					style={{ height: '46px', marginTop: '0px', marginRight: '15px' }}
				/>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/spartannash-favicon.png',
		marketplaceName: 'SpartanNash Specialty Direct'
	},
	suncity: {
		Navclass: '',
		topNavClass: '',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/sfp_logo_no_words.png',
		logo: (
			<Fragment>
				<span className="title mt-0">Sun City Marketplace</span>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/sfp-favicon.png',
		marketplaceName: 'SunCity Marketplace'
	},
	awg: {
		Navclass: '',
		topNavClass: '',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/awg_logo.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/awg_logo.png"
					alt="Associated Wholesale Grocers"
					style={{ height: '50px', marginTop: '0px', marginRight: '8px' }}
				/>
				<span className="title mt-0">AWG Marketplace</span>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/awg-favicon.png',
		marketplaceName: 'AWG Marketplace'
	},
	mdi: {
		Navclass: '',
		topNavClass: '',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/MDI_Marketplace.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/MDI_Marketplace.png"
					width="60"
					className="pt-0 pe-2 pb-0"
					alt="MDI Marketplace"
				/>
				<span className="title mt-0">MDI Marketplace</span>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/MDI_favicon.png',
		marketplaceName: 'MDI Marketplace'
	},
	davidson: {
		Navclass: '',
		topNavClass: '',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/davidson-marketplace-logo.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/davidson-marketplace-logo.png"
					width="100"
					className="pt-0 pe-2 pb-0"
					alt="Davidson Marketplace"
				/>
				<span className="title mt-0 small-break">Davidson Marketplace</span>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/CS-favicon.png',
		marketplaceName: 'Davidson Marketplace'
	},
	ags: {
		Navclass: 'ags',
		topNavClass: 'ags',
		bottomNavClass: 'ags',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/ags-marketplace-logo.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/ags-marketplace-logo.png"
					width="60"
					className="pt-0 pe-2 pb-0"
					alt="AGS Marketplace"
				/>
				<span className="title mt-0">AGS Marketplace</span>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/ags-favicon.png',
		marketplaceName: 'AGS Marketplace'
	},
	dpi: {
		Navclass: 'dpi',
		topNavClass: 'dpi',
		bottomNavClass: 'dpi',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/dpi-marketplace-link.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/dpi-marketplace-logo.png"
					width="190"
					className="pt-0 pe-0 pb-0 img-width"
					alt="DPI Marketplace Link"
				/>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/dpi-favicon.png',
		marketplaceName: 'DPI Marketplace'
	},
	bozzutos: {
		Navclass: 'bozzutos',
		topNavClass: 'bozzutos',
		bottomNavClass: 'bozzutos',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/bozzutos-marketplace-logo.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/bozzutos-marketplace-logo.png"
					width="50"
					className="pt-0 pe-1 pb-0"
					alt="Bozzuto's Virtual Marketplace"
				/>
				<span className="title mt-0 small-break">
					Bozzuto's Virtual Marketplace
				</span>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/bozzutos-favicon.png',
		marketplaceName: "Bozzuto's Virtual Marketplace"
	},
	mrcheckout: {
		Navclass: 'mrcheckout',
		topNavClass: 'mrcheckout',
		bottomNavClass: 'mrcheckout',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/mrcheckout-marketplace-logo.jpg',
		logo: (
			<Fragment>
				<img
					src="/assets/images/mrcheckout-marketplace-logo.jpg"
					width="50"
					className="pt-0 pe-1 pb-0"
					alt="Mr. Checkout Marketplace"
				/>
				<span className="title mt-0 small-break">
					Mr. Checkout Marketplace
				</span>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/mrcheckout-favicon.png',
		marketplaceName: "Mr. Checkout Marketplace"
	},
	lra: {
		Navclass: 'lra',
		topNavClass: 'lra',
		bottomNavClass: 'lra',
		contentClass: 'lra',
		modalClass: '',
		sidebarImagePath: '/assets/images/latinoresalliance.png',
		logo: '',
		favIcon: '',
		marketplaceName: 'Latino Restaurant Assocation'
	},
	mio: {
		Navclass: 'mio',
		topNavClass: 'mio',
		bottomNavClass: 'mio',
		contentClass: '',
		modalClass: '',
		sidebarImagePath: '/assets/images/mio-logo.jpeg',
		logo: '',
		favIcon: '%PUBLIC_URL%/mio-favicon.png',
		marketplaceName: 'Made In Oklahoma Coalition'
	},
	scsfa: {
		Navclass: 'scsfa',
		topNavClass: 'scsfa',
		bottomNavClass: 'scsfa',
		contentClass: 'scsfa',
		modalClass: '',
		sidebarImagePath: '/assets/images/scsfa-logo.png',
		logo: '',
		favIcon: '',
		marketplaceName: 'South Carolina Specialty Food Association'
	},
	vasfa: {
		Navclass: 'vasfa',
		topNavClass: 'vasfa',
		bottomNavClass: 'vasfa',
		contentClass: 'vasfa',
		modalClass: '',
		logo: '',
		favIcon: '',
	},
	urm: {
		Navclass: 'urm',
		topNavClass: 'urm',
		bottomNavClass: 'urm',
		contentClass: '',
		modalClass: '',
		logo: (
			<Fragment>
				<img
					src="/assets/images/URM_Logo_transparent.png"
					width="100"
					className="pt-0 pb-0"
					alt="URM"
				/>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/urm-favicon.png',
		marketplaceName: "URM Marketplace"
	},
	wlci: {
		Navclass: 'wlci',
		topNavClass: 'wlci',
		bottomNavClass: 'wlci',
		contentClass: 'wlci',
		modalClass: '',
		logo: '',
		favIcon: '',
	},
	dcs: {
		Navclass: 'dcs',
		topNavClass: 'dcs',
		bottomNavClass: 'dcs',
		contentClass: 'csspecialty-content',
		modalClass: 'csspecialty-modal',
		sidebarImagePath: '/assets/images/c&s-se-marketplace-logo.png',
		logo: (
			<Fragment>
				<img
					src="/assets/images/c&s-se-marketplace-logo.png"
					width="100"
					className="pt-2 pr-0 pb-0"
					alt="Davidson's, a C&S Specialty Market"
				/>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/cs-specialty-favicon.png',
		marketplaceName: "Davidson's, C&S Specialty Market"
	},
	pigdirect: {
		Navclass: 'pigdirect text-dark',
		topNavClass: 'pigdirect text-dark',
		bottomNavClass: 'pigdirect text-dark',
		contentClass: '',
		modalClass: '',
		logo: (
			<Fragment>
				<img
					src="/assets/images/pig-direct-logo.png"
					width="300"
					className="pt-4 pb-0"
					alt="Pig Direct"
				/>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/pig-direct-favicon.png',
		marketplaceName: "Pig Direct"
	},
	grocers: {
		Navclass: 'grocers text-dark',
		topNavClass: 'grocers text-dark',
		bottomNavClass: 'grocers text-dark',
		contentClass: '',
		modalClass: '',
		dropdownClass: 'grocers',
		logo: (
			<Fragment>
				<img
					src="/assets/images/grocers-logo.png"
					width="85"
					className="pt-4 pb-0"
					alt="Grocers Supply"
				/>
			</Fragment>
		),
		favIcon: '%PUBLIC_URL%/grocers-favicon.png',
		marketplaceName: "Grocers Supply Marketplace"
	}
}
