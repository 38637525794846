import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import '../../scss/elements/dropdown-admin.scss'
import { Dropdown } from 'antd'
import { connect } from 'react-redux'
import { toggleModal } from 'actions/modal'
import RecentUsers from './RecentUsers'
import { subdomainDetails } from 'utils/subdomain'
import { navLinkCls } from '../helpers'

const modal = {
	size: 'sm',
	title: 'Recent Users',
	modalBody: <RecentUsers />,
	showModal: true
}

const renderBody = ({ toggleModal, links, icon, user }) => {
	const subdomain = window.location.hostname.split('.')[0]

	const subdomainClass = subdomainDetails[subdomain] ? subdomainDetails[subdomain].Navclass : ''
	return (
		<div className={`admin-menu navbar-dropdown dropdown-admin bg-dark ${subdomainClass}`}>
			<div className="dropdown-inner">
				{links.map((link, i) => (
					<Fragment key={i}>
						{(!link.dev || (link.dev && user.is_developer === 1)) && (
							(!link.permission || user.user_permissions.includes(link.permission)) && (
								<div className="dropdown-item">
									{link.link === 'modal' ? (
										<a
											style={{ width: '100%' }}
											onClick={() => toggleModal(modal)}
										>
											<span className={link.icon} />
											&nbsp;&nbsp;
											<span>{link.name}</span>
										</a>
									) : (
										<Link
											to={link.link}
											style={{ width: '100%' }}
										>
											<span className={link.icon} />
											&nbsp;&nbsp;
											<span>{link.name}</span>
										</Link>
									)}
								</div>
							)
						)}
					</Fragment>
				))}
			</div>
		</div>
	)
}

const DropDownMenu = props => {
	const { user, icon, title } = props
	if (user !== null) {
		const menuBody = renderBody({ ...props })
		return (
			<Dropdown overlay={menuBody} trigger={['click']} placement="bottomLeft">
				<a className={navLinkCls}>
					{icon && <span className={`fa fa-${icon} fa-lg`} />}
					{title && <span className="list-link">{title}</span>}
				</a>
			</Dropdown>
		)
	} else {
		return <div />
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user.userData
	}
}

export default connect(
	mapStateToProps,
	{ toggleModal }
)(DropDownMenu)
