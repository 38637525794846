import { SET_CRUMB, RESET_CRUMB } from './types'

export const setCrumb = path => dispatch => {
	dispatch({
		type: SET_CRUMB,
		payload: path
	})
}

export const resetCrumb = () => dispatch => {
	dispatch({
		type: RESET_CRUMB
	})
}
