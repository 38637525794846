import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import withSizes from 'react-sizes'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import BreadCrumb from './BreadCrumb'
import { isMobile } from '../../helpers/MediaSizes'

//const layouts = ['top-navigation']

const Component = compose(
	withRouter,
	withSizes((sizes) => ({ isMobile: isMobile(sizes) })),
	connect(state => {
		return {
			//layout: state.config.layout,
			breadcrumb: state.breadcrumb,
			user: state.user.userData
		}
	}),
	branch(
		() => {
	
			//if (layouts.includes(layout)) return true
			return true
		},
		renderComponent(BreadCrumb),
		renderNothing
	)
)(BreadCrumb)

export default Component
