import React from 'react'
import clsx from 'clsx'
import NavBar from './NavBar'

const baseStartViewCls = ''
//tbd based off top navigation layout currently
// "overflow-auto w-100 h-100"

// basis for start views that include containers with navbars
export default function StartViewWrapper({ children, className, redirectPath }) {
	const retCls = clsx(baseStartViewCls, className)
	return <div className={retCls}>
    <NavBar redirectPath={redirectPath} />
    <div className='container-fluid main-content'>
    {children}
    </div>
  </div>
}
