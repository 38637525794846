import { fetchRecords } from 'helpers/Helper'
import { API_URL } from 'helpers/Constants'

// eslint-disable-next-line
import {
	GET_PARTNER_GROUPS,
	RESET_PARTNER_GROUPS,
	FETCHING_GROUPS
} from './types'

//Request UserData
export const getGroups = (callback = false) => async dispatch => {
	let options = {
		url: `${API_URL}/partnergroups?with=partners&limit=-1`
	}

	//Set Fetching User flag to avoid multiple request
	dispatch({ type: FETCHING_GROUPS, payload: true })

	const response = await fetchRecords(options)

	if (response !== null) {
		dispatch({ type: GET_PARTNER_GROUPS, payload: response.data.data })
	}

	//Set Fetching User flag to avoid multiple request
	dispatch({ type: FETCHING_GROUPS, payload: false })

	if (callback) {
		callback()
	}
	return response
}

export const resetGroups = () => async dispatch => {
	dispatch({
		type: RESET_PARTNER_GROUPS,
		payload: null
	})
}
