import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import {
	CardBody,
	Card,
	CardHeader,
	CardFooter,
	CardTitle,
	CardText
} from 'reactstrap'
import { RightCircleFilled, TagTwoTone } from '@ant-design/icons';
import { Collapse } from 'antd'
import { Badge, Button, Tooltip } from 'antd';
import {
	pick,
	groupBy,
	values,
	orderBy,
	sumBy,
	map as lMap
} from 'lodash'
import { currency } from 'helpers/Helper'
import { Link } from 'react-router-dom'


const getStoresOrders = storesObj => {
	if (!storesObj) return []
	const pStores = lMap(storesObj, function (ord) {
		const storeVals = pick(ord[0], [
			'store_hash',
			'store_name',
			'store_display_name'
		])
		//grouped by vendor?
		const orders = orderBy(
			ord,
			['vendor_name', 'purchasable_name'],
			['asc', 'asc']
		)
		//this is the total unrounded - actual
		const ordersTotalPrice = sumBy(orders, o => Number(o.final_sell_price))
		//needs purchase discounts - may be vendor specific
		const purchaseOrderDiscounts = orders[0].purchase_order_discounts || []

		const ordersTotalPurchaseDiscount = sumBy(purchaseOrderDiscounts, pod =>
			Number(pod.discount_amount)
		)
		//this is the total without discounts = discount_amount
		const ordersTotalSellPrice = sumBy(orders, v => {
			return Number(v.sell_price * v.qty)
		})
		//adjusted with any purchased discounts
		const ordersTotalAdjustedPrice =
			ordersTotalPrice - ordersTotalPurchaseDiscount

		const totalItems = sumBy(orders, o => Number(o.qty))
		return {
			...storeVals,
			orders: orders,
			totalOrders: orders.length,
			totalItems,
			ordersTotalPrice,
			ordersTotalSellPrice,
			ordersTotalAdjustedPrice,
			ordersTotalPurchaseDiscount,
			isDiscounted: ordersTotalSellPrice !== ordersTotalAdjustedPrice
		}
	})

	return orderBy(
		pStores,
		['ordersTotalAdjustedPrice', 'store_name'],
		['desc', 'asc']
	)
}

const DiscountBadge = ({ title = '', children, ...rest }) => {
	return (
        <Tooltip title={title} placement="left">
			<Badge
				offset={[6, 0]}
				status="success"
				//title={title}
				count={<TagTwoTone twoToneColor="#43a047" />}
				{...rest}>
				{children}
			</Badge>
		</Tooltip>
    );
}

const EmptyCart = ({ closeCart }) => {
	return (
		<CardBody className="bg-white text-center">
			<CardTitle>Your cart is currently empty</CardTitle>
			<CardText>Start a new order or browse products.</CardText>

			<Link
				to="/order/start"
				className="btn btn-icon btn-info btn-sm justify-content-center align-items-center text-white"
				onClick={closeCart}>
				<i className="fa fa-cart-plus" />
				<span>Start Order</span>
			</Link>
		</CardBody>
	)
}

const StoreHeader = ({ st, onCheckout }) => {
	const totalCost = currency(st.ordersTotalAdjustedPrice)
	const discountTotal = st.isDiscounted ? (
		<DiscountBadge
			title={`${currency(
				st.ordersTotalSellPrice - st.ordersTotalAdjustedPrice
			)} Total Savings`}>
			{totalCost}
		</DiscountBadge>
	) : (
		totalCost
	)
	return (
		<div className="d-flex justify-content-end align-items-center flex-grow-1">
			<div className="flex-grow-1 me-1">
				<strong>{st.store_display_name} </strong>
				<sup>({st.totalItems})</sup>
			</div>
			<div className="me-4 font-weight-bold">{discountTotal}</div>

			<Button
				type="default"
				//className={st.isDiscounted ? 'text-success' : 'text-primary'}
				size="small"
				//icon="right-circle"
				onClick={e => {
					//e.stopPropagation()
					onCheckout(st)
				}}>
				Checkout{' '}
				<RightCircleFilled style={{ fontSize: 14 }} />
			</Button>
		</div>
    );
}

const OrderAmt = ({ord}) => {
	const discountedItemPrice = Number(ord.final_sell_price / ord.qty)
	const isDiscounted = ord.discount_amount !== 0
	const finalCost = currency(ord.final_sell_price)
	const discountFinal = isDiscounted ? (
		<DiscountBadge
			title={`${currency(ord.discount_amount)} Savings`}
			className="fs-px-13">
			<strong>{finalCost}</strong>
		</DiscountBadge>
	) : (
		finalCost
	)
	return (
		<div className="d-flex justify-content-between flex-spacer-2 align-items-center flex-nowrap">
			<div className="text-muted flex-spacer-1of3 text-end small">
				{currency(discountedItemPrice)}{' '}
				{isDiscounted && <s>{currency(ord.sell_price)}</s>}
			</div>
			<div className="text-muted flex-spacer-1of3 text-center small">
				{ord.qty}
			</div>
			<div className="text-muted flex-spacer-1of3 text-end fs-px-13 text-nowrap">
				{discountFinal}
			</div>
		</div>
	)
}

const { Panel } = Collapse

const CartOrders = props => {
	const { storeOrders, closeCart, contentClass, dropdownClassName, isMobile } = props
	//const storeCartOrders = getStoresOrders(null)
	const history = useHistory();
	const storeCartOrders = getStoresOrders(storeOrders)
	const activeCart = storeCartOrders && storeCartOrders.length > 0
	const grandTotalPrice = sumBy(storeCartOrders, o =>
		Number(o.ordersTotalAdjustedPrice)
	)
	//const grandTotalOrders = sumBy(storeCartOrders, to => Number(to.totalOrders))
	const grandTotalItems = sumBy(storeCartOrders, to => Number(to.totalItems))
	//console.log('CartOrders', storeCartOrders)

	const onCheckout = useCallback(
		(store) => {
			//event.stopPropagation()
			//console.log('CHECKOUT', store)
			closeCart()
			//this does not rerender if in cart
			history.push(`/order/start/${store.store_hash}/cart`)
			//this reloads whole app though
			//window.location.href = `/order/start/${store.store_hash}/cart`
		},
		[closeCart]
	)


	return (
		<div
			className={`cart-orders ${contentClass}`}
			style={{
				width: activeCart ? 440 : 'auto',
				//maxHeight: '640px',
				//overflowX: 'auto'
				//potentially get base color from db theme?
			}}>
			<Card className="card-match">
				<CardHeader className={`d-flex justify-content-between align-items-center text-white font-weight-bold py-2 ${dropdownClassName}`}>
					<div className="">Cart Orders</div>
					<div className="">{currency(grandTotalPrice)}</div>
				</CardHeader>
				{activeCart ? (
					<CardBody className="px-0 py-0">
						<Collapse accordion bordered={false} className="">
							{storeCartOrders.map((so, idx) => {
								//let classColor = value && value.indexOf(f) !== -1 ? 'success' : 'info'
								return (
									<Panel
										header={<StoreHeader st={so} onCheckout={onCheckout} />}
										key={so.store_hash}
										//forceRender
										showArrow={!isMobile}
										//extra={genExtra()}
										className="">
										<ul className="list-group list-group-flush">
											{so.orders.map(ord => {
												return (
													<li
														className="list-group-item mx-0 py-1 px-3 d-flex justify-content-between align-items-center"
														key={ord.hashid}>
														<Link
															className="text-info flex-spacer-3"
															to={{
																pathname: `/product/view/${ord.product_hash}`,
																state: {
																	isFromCart: true,
																	store_hash: so.store_hash
																}
															}}
															onClick={closeCart}>
															<h6 className="font-weight-bold fs-px-13">
																{ord.purchasable_name}
															</h6>
														</Link>

														{<OrderAmt ord={ord} />}
													</li>
												)
											})}
										</ul>
									</Panel>
								)
							})}
						</Collapse>
					</CardBody>
				) : (
					<EmptyCart closeCart={closeCart} />
				)}

				{activeCart && (
					<CardFooter className={`text-center text-white font-weight-bold border-top-0 py-2 ${dropdownClassName}`}>
						{`${grandTotalItems} Items from ${storeCartOrders.length} Stores`}
					</CardFooter>
				)}
			</Card>
		</div>
	)
}

const mapStateToProps = (state) => {
	const { unconfirmedOrders } = state
	return {
		storeOrders: groupBy(values(unconfirmedOrders.orders), 'store_hash')
	}
}

export default connect(mapStateToProps)(CartOrders)
