import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import Navbar from './Navbar'
import withSizes from 'react-sizes'
import { isMobile } from '../../helpers/MediaSizes'


const Component = compose(
	withSizes((sizes) => ({ isMobile: isMobile(sizes) })),
	connect(state => {
		return {
			navigation: state.navigation,
			suppliernav: state.suppliernav,
		}
	}),
	branch(
		() => true,
		renderComponent(Navbar),
		renderNothing
	)
)(Navbar)

export default Component
