import { compose, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/index'
import { getLocalAccessToken, getLocalPartnerContext } from './api/storage.service'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
	const middlewares = [thunk];
	const store = createStore(rootReducer,
		{
			auth: { authenticated: getLocalAccessToken() },
			partnerContext: getLocalPartnerContext(true)
		},
		composeEnhancers(applyMiddleware(...middlewares))
	)
	if (process.env.REACT_APP_ENVIRONMENT !== 'Production' && module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducers', () => {
			const nextRootReducer = require('./reducers/index')
			store.replaceReducer(nextRootReducer)
		})
	}
	return store
}

