import React, { Suspense, useState, useEffect, Fragment } from 'react'
//import { Redirect, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import Header from '../../elements/Header'
import LeftSidebar from '../../elements/left-sidebar'
import BreadCrumb from '../../elements/breadcrumb'
import AuthenticatedUpdater from '../../provider/AuthenticatedUpdater'
import './main.scss'
//import { useMemoizedCallback } from '../../libs/hooks'

const baseMainCls = 'overflow-auto w-100 h-100 main'
const baseContainerCls = 'container-fluid main-content'

// tbd Main layout, with header / left nav bar etc
//error boundary here ?
//top navigation
//deal with layout/config ; bread crumb ; NotificationsSystem
//todo get rid of intermdiary layers col row - may not need container fluid
//authenticated ? etc
//checking here - should possible make this lazy and check earlier?
//yes because not redirect yet and check user loading
//NEEDS redirect or private

/* 
<div
    data-layout={layout}
    data-background={background}
    data-navbar={navbar}
    data-top-navigation={topNavigation}
    data-logo={logo}
    //data-left-sidebar={leftSidebar}
    //data-collapsed={collapsed}
    className="overflow-auto w-100 h-100"> 
    <NotificationsSystem theme={theme} />
  */

/* <Redirect
			to={{
				pathname: isRedirectStart ? `/start${props.location.pathname}` : '/login',
				state: { from: props.location }
			}}
		/> */

export default function MainLayout({
	children,
	className,
	//userData,
	subdomainClass
}) {
	const retCls = clsx(baseMainCls, className)
	const containerCls = clsx(baseContainerCls, subdomainClass)
	const [showMenu, setShowMenu] = useState(false)
	//const location = useLocation()
	useEffect(() => {
		console.log('Mounted')
		return () => {
			console.log('UNMOUNTED')
		}
	}, [])
	const toggleMenu = () => {
		setShowMenu(prev => !prev)
	}
	/* const showBreadCrumb = useMemo(() => {
		if (!userData) {
			return false
		}
		return userData.is_supplier
			? userData.supplier.data.is_onboarding !== 1
			: true
	}, [userData]) */
	//THIS NEEDS TO RETURN CHILDREN as passed from route

	return (
		<AuthenticatedUpdater>
			{({ isAuthenticated }) => {
				//console.log("MAIN", isAuthenticated, isAuthPending)
				/* if (isAuthPending) {
					return (
						<div
							style={{
								position: 'absolute',
								top: 0,
								bottom: 0,
								left: 0,
								right: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}>
							<h2>Initializing...</h2>
						</div>
					)
				} */
				return (
					<div className={retCls}>
						{isAuthenticated && (
							<Fragment>
								<Header toggleDrawer={toggleMenu} />
								<LeftSidebar show={showMenu} toggle={toggleMenu} />
							</Fragment>
						)}

						<div className={containerCls}>
							{isAuthenticated && <BreadCrumb isHideOnMobile={true} />}

							<Suspense
								fallback={
									<div className="h-100 d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle">
										<h4>Page is Loading...</h4>
									</div>
								}>
								{children}
							</Suspense>
						</div>
					</div>
				)
			}}
		</AuthenticatedUpdater>
	)
}
