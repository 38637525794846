import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import TopNavigation from './TopNavigation'


const Component = compose(
	connect(state => {
		return {
			navigation: state.navigation,
			suppliernav: state.suppliernav,
			user: state.user.userData
		}
	}),
	branch(
		() => true,
		renderComponent(TopNavigation),
		renderNothing
	)
)(TopNavigation)

export default Component
