import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import Can from './Can'

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user.userData,
		user_permissions:
			state.user.userData !== null ? state.user.userData.user_permissions : [],
		permission: ownProps.permission
	}
}

const Component = compose(
	connect(mapStateToProps),
	branch(
		({ user, permission, user_permissions }) => {
			if (user !== null) {
				if (user_permissions.indexOf(permission) !== -1) {
					return true
				}
			}
			return false
		},
		renderComponent(Can),
		renderNothing
	)
)(Can)

export default Component
