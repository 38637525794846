import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { fetchRecords } from 'helpers/Helper'
import { API_URL } from 'helpers/Constants'
import { navLinkCls, navBadgeCls } from '../helpers'

class SupplierOnboardingStatus extends Component {
	constructor(props) {
		super(props)
		this.state = {
			updateCount: 0
		}
	}

	componentDidMount() {
		this.mounted = true;
		this.fetchSupplierUpdateCount()
	}

	componentWillUnmount(){
		this.mounted = false;
	}

	fetchSupplierUpdateCount = async () => {
		let options = {
			url: `${API_URL}/supplier/onboarding/suppliers/count`
		}
		let res = await fetchRecords(options, this.props.notify)
		if (res !== null && this.mounted) {
			this.setState({
				supplierCount: res.data.in_progress,
				loading: false
			})
		}
	}

	render() {
		return (
			<Fragment>
				<Link
					to="/admin/supplier/onboardingstatus"
					className={navLinkCls}>
					{this.state.supplierCount > 0 && (
						<span className={navBadgeCls}>
							{this.state.supplierCount}
						</span>
					)}
					<span className={`fa fa-chess fa-lg`} />
				</Link>
			</Fragment>
		)
	}
}

export default SupplierOnboardingStatus
