import React from 'react'
import clsx from 'clsx'

const baseHomeViewCls = 'home-view'

// "overflow-auto w-100 h-100"

// basis for home views that include containers
export default function HomeViewWrapper({ children, className }) {
	const retCls = clsx(baseHomeViewCls, className)
	return <div className={retCls}>
    <div className='container-fluid main-content'>
    {children}
    </div>
  </div>
}
