import { combineReducers } from 'redux'
import auth from './auth'
import user from './user'
import modal from './modal'
import favoriteFilter from './favoriteFilter'
import partnerContext from './partnerContext'
import partnerGroups from './partnerGroups'
import partners from './partners'
import tooltip from './tooltip'
import unconfirmedOrders from './unconfirmedOrders'
import { reducer as formReducer } from 'redux-form'
import { navigation } from './navigation'
import { suppliernav } from './suppliernav'
import { resetPassword } from './pages/reset-password'
import { reducer as notificationsReducer } from 'reapop'
import tour from './tour'
import breadcrumb from './breadcrumb'
import downloadQueue from './downloadQueue'
import storedTableState from './storedTableState'

const rootReducer = combineReducers({
	auth,
	user,
	partnerContext,
	partnerGroups,
	partners,
	unconfirmedOrders,
	modal,
	form: formReducer,
	navigation,
	suppliernav,
	resetPassword,
	notifications: notificationsReducer(),
	tooltip,
	tour,
	breadcrumb,
	downloadQueue,
	favoriteFilter,
	storedTableState
})
export default rootReducer
