import { SET_CONTEXT, RESET_CONTEXT } from '../actions/types'

const INITIAL_STATE = {
	type: null,
	hash: null,
	name: null
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_CONTEXT:
			return action.payload
		case RESET_CONTEXT:
			return INITIAL_STATE
		default:
			return state
	}
}
