import React from 'react'
import { SyncOutlined } from '@ant-design/icons';
import { List } from 'antd';
import _ from 'lodash'

const DownloadQueue = ({ downloadQueue }) => {
	return (
        <div className="p-2" style={{ backgroundColor: "#9575cd" }}>
			<List
				bordered
				dataSource={_.values(downloadQueue)}
				renderItem={item => (
					<List.Item className="text-white">
						<SyncOutlined spin style={{ fontSize: '22px', display: 'contents' }} />{' '}
						<span className="font-weight-bold ms-2">{item.msg}</span>
					</List.Item>
				)}
			/>
		</div>
    );
}

export default DownloadQueue
