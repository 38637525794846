import { TOGGLE_MODAL } from '../actions/types'

const INITIAL_STATE = {
	showModal: false,
	modalBody: null,
	size: 'lg',
	title: '',
	className: null,
	headerColor: 'primary',
	scrollable: false
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case TOGGLE_MODAL:
			return action.payload === null ? INITIAL_STATE : action.payload
		default:
			return state
	}
}
