import React from 'react'
import { Navbar, Nav, NavItem } from 'reactstrap'
import PartnerContext from 'elements/navbar/PartnerContext'
import { Link } from 'react-router-dom'
import Can from 'hoc/Can'
import 'scss/elements/mobile-navbar.scss'
import { subdomainDetails } from '../../utils/subdomain'

const MobileNav = ({ user, partners }) => {

	if (user === null) {
		return null
	}

	let showContextMenu = partners && (partners.length > 1) && (user.is_supplier !== 1)

	let subdomain = window.location.hostname.split('.')[0]

	let subdomainBottomNavClass = subdomainDetails[subdomain]
		? subdomainDetails[subdomain].bottomNavClass
		: ''

	return (
		<Navbar dark container={true} className={`mobile-navbar d-lg-none bg-dark ps-0 pe-0 ${subdomainBottomNavClass}`}>
			<Nav className="justify-content-center flex-grow-1">
				{showContextMenu && (
					<NavItem className="nav-item nav-item-dropdown me-2">
						<PartnerContext className="" />
					</NavItem>
				)}
				{user.is_supplier !== 1 && (
					<NavItem className="nav-item nav-item-dropdown">
						<button
							id="order-start"
							className="ant-btn ant-btn-background-ghost ant-dropdown-trigger">
							<Link className="align-middle mt-5" to="/order/start">
								START ORDER
							</Link>
						</button>
					</NavItem>
				)}
			</Nav>
		</Navbar>
	)
}

export default MobileNav
