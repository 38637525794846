import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import DownloadQueue from './DownloadQueue'
import _ from 'lodash'

const Component = compose(
	connect(state => {
		return {
			downloadQueue: state.downloadQueue
		}
	}),
	branch(
		({ downloadQueue }) =>
			_.values(downloadQueue).length > 0,
		renderComponent(DownloadQueue),
		renderNothing
	)
)(DownloadQueue)

export default Component
