import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import App from './App'
import {Auth0Provider} from "@auth0/auth0-react";

/* if (process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ATATUS_KEY) {
	let domains =
		process.env.REACT_APP_ENVIRONMENT === 'Production'
			? [
					'sfp.market',
					'sfa.sfp.market',
					'davidson.sfp.market',
					'mdi.sfp.market',
					'agbr.sfp.market',
					'chex.sfp.market',
					'spartannash.sfp.market'
			  ]
			: [
					'sfpmarket.dev',
					'sfa.sfpmarket.dev',
					'davidson.sfpmarket.dev',
					'mdi.sfpmarket.dev',
					'agbr.sfpmarket.dev',
					'chex.sfpmarket.dev',
					'spartannash.sfpmarket.dev'
			  ]

} */

//require('dotenv').config()
export const store = configureStore()
const Component = () => (

	<Provider store={store}>
			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN}
				redirectUri={window.location.origin + '/callback'}
				clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
				audience={"raiden-api"}
			>
				<App />
			</Auth0Provider >
	</Provider>
)

let render = () => {
	ReactDOM.render(<Component />, document.getElementById('root'))
}
render()
if (process.env.REACT_APP_ENVIRONMENT !== 'Production' && module.hot) {
	module.hot.accept()//('./App' , () => { render() })
}
