import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import HasRole from './HasRole'

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user.userData,
		roles: ownProps.roles,
		exclusion: ownProps.exclusion
	}
}

const Component = compose(
	connect(mapStateToProps),
	branch(
		({ user, roles, exclusion }) => {
			if (user !== null) {
				if (!exclusion && roles.indexOf(user.role_name) !== -1) {
					return true
				}

				if (exclusion && roles.indexOf(user.role_name) === -1) {
					return true
				}
			}

			return false
		},
		renderComponent(HasRole),
		renderNothing
	)
)(HasRole)

export default Component
