import React from 'react'
import { Navbar as NavigationBar, Nav, NavItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Logo from '../../elements/logo'
import { subdomainDetails } from '../../utils/subdomain'
import { defaultUnauthorizedRoute } from '../../routes/routeHelpers'

const baseNavBarCls = 'navbar bg-dark'

// basic nav bar used for start items
// adjust without extraneous css from src\scss\elements\navbar-2.scss
// top navigation
//redirectPath to change link state for login
export default function NavBar({ barClass, redirectPath }) {
	const subdomain = window.location.hostname.split('.')[0]

	const subdomainClass = subdomainDetails[subdomain]
		? subdomainDetails[subdomain].Navclass
		: ''
	const retCls = clsx(baseNavBarCls, subdomainClass, barClass)
	const linkTo = redirectPath
		? {
				pathname: defaultUnauthorizedRoute,
				state: { from: { pathname: redirectPath } }
		  }
		: defaultUnauthorizedRoute
	return (
		<NavigationBar
			// expand="lg"
			dark
			className={retCls}>
			<div className="ms-1 navbar-brand">
				<Logo />
			</div>
			<Nav style={{ color: 'white', marginLeft: 'auto' }}>
				<NavItem className="nav-item nav-item-dropdown me-4">
					<Link to={linkTo} className="btn btn-outline-light">
						<span className={`fa fa-user-alt`} /> LOGIN
					</Link>
				</NavItem>
			</Nav>
		</NavigationBar>
	)
}
