import { fetchRecords } from 'helpers/Helper'
import { API_URL } from 'helpers/Constants'
import { setContext } from './partnerContext'
import _ from 'lodash'

// eslint-disable-next-line
import { GET_PARTNERS, RESET_PARTNERS, FETCHING_PARTNERS } from './types'

//Request UserData
export const getPartners = (setFirstPartner = false, callback = false) => async dispatch => {
	let options = {
		url: `${API_URL}/partners?limit=-1`
	}

	//Set Fetching User flag to avoid multiple request
	dispatch({ type: FETCHING_PARTNERS, payload: true })

	const response = await fetchRecords(options)

	if (response !== null) {
		let partners = response.data.data

		//Set Partner Context if user only have access to one partner.
		if (partners.length === 1) {
			dispatch(setContext('Partner', partners[0].hashid, partners[0].name))
		} else if(partners.length > 0 && setFirstPartner) {
			// group partners by partner group - grab first alphabetical partner group,
			// sort its partners alphabetically, grab first one
			let partnerGroups = _.groupBy(_.sortBy(partners, 'partner_group_name'), p => p.partner_group_hash || "Others")
			let firstPartnerGroup = partnerGroups[Object.keys(partnerGroups)[0]]
			let firstPartner = _.sortBy(firstPartnerGroup, 'name')[0]
			dispatch(setContext('Partner', firstPartner.hashid, firstPartner.name))
		}

		dispatch({ type: GET_PARTNERS, payload: partners })
	}

	//Set Fetching User flag to avoid multiple request
	dispatch({ type: FETCHING_PARTNERS, payload: false })

	if (callback) {
		callback()
	}
	return response
}

export const resetPartners = () => async dispatch => {
	dispatch({
		type: RESET_PARTNERS,
		payload: null
	})
}
