import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom'

class CustomRoute extends Component {

    constructor(props) {
        super(props);
    }

    hasPermission() {
        const { permission, user } = this.props

        if (permission === undefined) {
            return true
        }

        if (user.user_permissions.includes(permission)) {
            return true
        }

        return false;
    }

    render() {
        return (this.hasPermission() ?
            <Route {...this.props} /> :
            <Redirect to='/' />
        )
    }
}

export default CustomRoute;