const urls = [
	{
		title: 'Navigation',
		items: [
			{
				url: '/',
				icon: 'dashboard',
				title: 'Dashboard',
				badge: {
					className: 'badge bg-danger badge-sm badge-outline',
					title: 'New'
				},
				items: [],
				enabled: true
			},
			{
				url: '/supplier/onboarding',
				icon: '',
				title: 'Account Setup',
				items: [],
				enabled: true
			},
			{
				url: '/supplier/profile',
				icon: '',
				title: 'Profile',
				items: [],
				enabled: true
			},
			{
				url: '/supplier/assets',
				icon: '',
				title: 'Assets',
				items: [],
				enabled: true
			},
			{
				url: '/supplier/products',
				icon: '',
				title: 'Products',
				items: [],
				enabled: true
			},
			{
				url: '/supplier/sample',
				icon: '',
				title: 'Sample Requests',
				items: [],
				enabled: true
			},
			{
				url: '/supplier/orders/upcoming',
				icon: '',
				title: 'Upcoming Orders',
				items: [],
				enabled: true
			},
			{
				url: '/supplier/orders/historical',
				icon: '',
				title: 'Historical Orders',
				items: [],
				enabled: true
			},
			{
				url: '/supplier/promotions',
				icon: '',
				title: 'Promotions',
				items: [],
				enabled: true
			},
			{
				url: '',
				icon: '',
				title: 'Support',
				items: [
					{
						url: '/supplier/faq/faqs',
						icon: '',
						title: 'FAQs',
						items: []
					},
					{
						url: '/supplier/faq/video-tutorials',
						icon: '',
						title: 'Video Tutorials',
						items: []
					},
					{
						url: '/supplier/faq/additional-resources',
						icon: '',
						title: 'Additional Resources',
						items: []
					}
				],
				enabled: true
			}
		]
	}
]
export function suppliernav(state = Array.from(urls), action) {
	switch (action.type) {
		case 'SET_NAVIGATION':
			return Object.assign({}, state, {
				...action.suppliernav
			})
		default:
			return state
	}
}
