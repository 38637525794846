import { QUEUE_PUSH, QUEUE_REMOVE } from './types'
import moment from 'moment'
//import momentLocaliser from 'react-widgets-moment'

//momentLocaliser(moment)

export const queuePush = data => dispatch => {
	let id = moment().format('x')
	dispatch({ type: QUEUE_PUSH, payload: { ...data, id } })
	return id
}

export const queueRemove = id => dispatch => {
	dispatch({ type: QUEUE_REMOVE, payload: id })
}
