import React from 'react'
import { Drawer, Menu } from 'antd'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { isMobile } from '../../helpers/MediaSizes'
import withSizes from 'react-sizes'

const { SubMenu } = Menu

const LeftSidebar = ({ user, navigation, suppliernav, show, toggle }) => {
	if (user === null) {
		return null
	}

	//deal with bug that leaves the document body: position: relative; width: calc(100% - 12px);
	//on close not cleaning up - effects layout especially on mobile
	const handleAfterVisible = v => {
		//console.log("left drawer change ", v)
		if (!v) {
			document.body.style = ""
		}
	}

	let items = []

	if (user.is_supplier === 1) {
		//Supplier Navigation
		const onboardedItems = [
			'Profile',
			'Assets',
			'Stores',
			'Products',
			'Upcoming Orders',
			'Historical Orders',
			'Payments'
		]
		items = _.cloneDeep([...suppliernav])
			.map(item => item.items)
			.reduce((a, b) => [...a, ...b])
		//Is onboarding hide onboardedItems
		if (user.supplier.data.is_onboarding === 1) {
			items = items.filter(i => !onboardedItems.includes(i.title))
		} else {
			items = items.filter(i => i.title !== 'Account Setup')
		}
	} else {
		items = _.cloneDeep([...navigation])
			.map(item => item.items)
			.reduce((a, b) => [...a, ...b])

		//Add Vendors Database Link to Corresponding Users
		/* if (
			user.show_vendors_db === 1 &&
			user.user_permissions.includes('vendorsdb-access')
		) {
			items.push({
				id: 'vdb',
				url: '/vendors-db',
				title: 'Vendors Database',
				items: []
			})
		} */
		//Add Admin Link to Corresponding Users
		if (
			user !== null &&
			(user.role_name === 'Operations' || user.role_name === 'Developer')
		) {
			items.push({
				id: 'admin',
				url: '/admin',
				title: 'Admin',
				items: []
			})
		}
		//Check for role specific links
		if (user !== null) {
			items.forEach(function (element, index) {
				if ('roles' in element) {
					if (!element.roles.includes(user.role_name)) {
						items.splice(index, 1);
					}
				}
			});
		}
	}

	const menuItems = items
		.map(item => {

			if (isMobile) {
				// Removing *Reports*, *Credits* and *Stores* options when in mobile
				if (item.title.toLowerCase() === 'stores'
					|| item.title.toLowerCase() === 'reports'
					|| item.title.toLowerCase() === 'credits') return null

				// Removing *Sample Requests* submenu option from *Vendors* when in mobile
				if (item.title.toLowerCase() === 'vendors' && item.items) {
	
					let auxItemsArray = item.items.filter(i => i.url !== '/samplerequests')
	
					return  (auxItemsArray.length > 1) ? { 
						...item,
						items: auxItemsArray
					} : auxItemsArray[0]
				}

			}

			return item
		})
		.map((nav, key) => {
			if (nav) {
				if (nav.items && nav.items.length > 0) {
					return (
						<SubMenu key={`sub${key}`} title={nav.title}>
							{
								nav.items.map((item, subKey) => {
									return <Menu.Item key={`sub${key}-${subKey}`}>
										<Link to={item.url} className="list-link" id={item.id}>
											<div>{item.title}</div>
										</Link>
									</Menu.Item>
								})
							}
						</SubMenu>
					)
				} else {
					return (
						<Menu.Item key={key}>
							<Link to={nav.url} className="list-link" id={nav.id}>
								<div>{nav.title}</div>
							</Link>
						</Menu.Item>
					)
				}
			}

			return ''
		})

	const title = (
		<div>
			<i className="fas fa-user me-1" />
			<span>{user.name}</span>
		</div>
	)

	return (
		<Drawer
			visible={show}
			//destroyOnClose
			afterVisibleChange={handleAfterVisible}
			zIndex="1500"
			onClose={toggle}
			placement="left"
			title={title}
			drawerStyle={{
				backgroundColor: '#001529',
				color: 'white'
			}}
			bodyStyle={{ padding: 0 }}>
			<Menu mode="inline" theme="dark" onClick={toggle}>
				{menuItems}
			</Menu>
		</Drawer>
	)
}

const mapSizesToProps = sizes => ({
	isMobile: isMobile(sizes)
})

export default (withSizes(mapSizesToProps)(LeftSidebar))
