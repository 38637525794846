import {
	GET_PARTNER_GROUPS,
	RESET_PARTNER_GROUPS,
	FETCHING_GROUPS
} from '../actions/types'

const INITIAL_STATE = {
	groups: null,
	fetchingGroups: false
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_PARTNER_GROUPS:
			return { ...state, groups: action.payload }
		case FETCHING_GROUPS:
			return { ...state, fetchingGroups: action.payload }
		case RESET_PARTNER_GROUPS:
			return INITIAL_STATE
		default:
			return state
	}
}
