import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import LeftSidebar from './LeftSidebar'

const Component = compose(
	connect(state => {
		return {
			navigation: state.navigation,
			suppliernav: state.suppliernav,
			user: state.user.userData
		}
	}),
	branch(
		() => true,
		renderComponent(LeftSidebar),
		renderNothing
	)
)(LeftSidebar)

export default Component
