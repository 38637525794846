
import { addNotification as notify } from 'reapop'
import Axios from 'axios'
import { AUTH_USER, AUTH_ERROR, GET_USER, AUTH_EXPIRATION, USER_ACTIVE } from './types'
import { API_URL } from 'helpers/Constants'
import { getUser } from './user'
import { resetContext } from './partnerContext'
import { resetGroups } from './partnerGroups'
import { resetPartners } from './partners'
import { resetUncOrders } from './unconfirmedOrders'
import { removeAllTokens, setAllTokens } from '../api/storage.service'
import { showScanTooltip } from './tooltip'

import { authApi, getApiHeader } from '../api/api'
import { selectUserName } from '../reducers/user'
import EventBus from '../helpers/EventBus'
import { APP_URL_DEV, APP_URL_LOCAL, APP_URL_PRODUCTION, HUBSPOT_API_ID, HUBSPOT_CODE, HUBSPOT_LOGIN_FORM_ID, HUBSPOT_REGISTER_SUPPLIER_FORM_ID } from '../helpers/Constants'
import { handleHubspotLoginForm, handleHubspotRegisterSupplierForm, handleHubspotSupplierActivateForm } from '../helpers/HubspotMethods'

// clear auth/user state completely
export const clearAuth =
	(isPartner = true) =>
	async dispatch => {
		dispatch({ type: AUTH_USER, payload: null })
		if (isPartner) {
			//Remove Partner/Orders Context from Redux Storage on logout
			dispatch(resetContext())
			dispatch(resetGroups())
			dispatch(resetPartners())
			dispatch(resetUncOrders())
		}

		removeAllTokens(isPartner)
		dispatch({ type: AUTH_EXPIRATION, payload: false })
		dispatch({ type: GET_USER, payload: null })
		dispatch({ type: USER_ACTIVE, payload: false })
		EventBus.dispatch("logout");
	}

// reset soft - leave AUTH_USER auth.authenticated intact
export const resetAuth =
	(isPartner = true) =>
	async dispatch => {
		if (isPartner) {
			//Remove Partner/Orders Context from Redux Storage on logout
			dispatch(resetContext())
			dispatch(resetGroups())
			dispatch(resetPartners())
			dispatch(resetUncOrders())
		}

		removeAllTokens(isPartner)
		dispatch({ type: AUTH_EXPIRATION, payload: false })
		dispatch({ type: GET_USER, payload: null })
	}

export const setAuth = (accessToken, refreshToken) => async dispatch => {
	dispatch({ type: AUTH_USER, payload: accessToken })
	await setAllTokens(accessToken, refreshToken)
}

//cleanup should be done before
//Login Request
export const login = (formProps, subdomain, callback) => async dispatch => {
	try {
		const response = await authApi.post(`${API_URL}/auth/login`, formProps)
		let getUserRes = null;

		//soft reset?
		await dispatch(resetAuth(true))
		//Remove Partner/Orders Context from Redux Storage on logout
		/* dispatch(resetContext())
		dispatch(resetGroups())
		dispatch(resetPartners())
		dispatch(resetUncOrders())

		//Remove Token from Local Storage
		localStorage.removeItem('raiden_token')
		localStorage.removeItem('partner_context')

		//Remove User Data from Redux Storage
		dispatch({ type: GET_USER, payload: null })

		dispatch({ type: AUTH_EXPIRATION, payload: false })

		dispatch({ type: AUTH_USER, payload: response.data.access_token }) */

		//await localStorage.setItem('raiden_token', response.data.access_token)

		// if (subdomain === 'sfa') {
		// }
		await dispatch(
			setAuth(response.data.access_token, response.data.refresh_token)
		)
		await handleHubspotLoginForm(formProps.email);

		await dispatch(getUser())
			.then((res) => {
				//redirecting after login
				//if its a SFA register, send a request to login inside the main domain
				if (res.data.data.is_supplier === 1) {
					let baseUrl = '';
					if (process.env.REACT_APP_ENVIRONMENT === 'Production') {
						baseUrl = APP_URL_PRODUCTION;
					} else if (process.env.REACT_APP_ENVIRONMENT === 'Local') {
						baseUrl = APP_URL_LOCAL;
					} else {
						baseUrl = APP_URL_DEV;
					}
					console.log("REDIRECT ")
					if (subdomain === 'sfa') {
						window.location.replace(`${baseUrl}/tokenredirect/${response.data.access_token}/${response.data.refresh_token}`);
					} else {
						window.location.replace('/')
					}
				}

				if (callback) callback()
				return response
			})
		dispatch(showScanTooltip())
	} catch (e) {
		dispatch({ type: AUTH_ERROR, payload: e.response.data.message })
	}
}


//Logout Request
export const logout = callback => async (dispatch, getState) => {
	const notifyId = 'logout_user'
	const userName = selectUserName(getState()) || 'User'
	const headers = getApiHeader()
	dispatch(
		notify({
			message: '...Logging Out',
			id: notifyId,
			status: 'loading',
			position: 'tr',
			dismissAfter: 0, //will make indefinite
			dismissible: false
		})
	)
	try {
		await authApi({
			url: `${API_URL}/auth/logout`,
			method: 'get',
			headers
		})
		dispatch(clearAuth(true))

		if (callback) callback()
		dispatch(
			notify({
				message: `${userName} - Logged Out`,
				id: notifyId,
				status: 'success',
				position: 'tr',
				dismissAfter: 3000,
				dismissible: true
			})
		)
		return true
	} catch (e) {
		dispatch({ type: AUTH_ERROR, payload: 'COULD NOT LOGOUT' })
		dispatch(
			notify({
				message: `Could Not Logout: ${userName}`,
				id: notifyId,
				status: 'error',
				position: 'bc',
				dismissAfter: 5000,
				dismissible: true
			})
		)
	}
}

//Login Request
export const activate = (formProps, subdomain) => async dispatch => {
	const response = await authApi
		.post(`${API_URL}/auth/activate/supplier`, formProps)
		.catch(err => {
			dispatch({
				type: AUTH_ERROR,
				payload:
					'This email address is already registered. Please try logging in'
			})
		})

	await dispatch(
		setAuth(response.data.access_token, response.data.refresh_token)
	)


	let baseUrl = '';
	if (process.env.REACT_APP_ENVIRONMENT === 'Production') {
		baseUrl = APP_URL_PRODUCTION;
	} else if (process.env.REACT_APP_ENVIRONMENT === 'Local'){
		baseUrl = APP_URL_LOCAL;
	} else {
		baseUrl = APP_URL_DEV;
	}
	
	dispatch(getUser())
	.then(res => {
		handleHubspotSupplierActivateForm(formProps.email, formProps.name, formProps.lastname)
		.then(res => {
		//redirecting after login
		subdomain === 'sfa' ?
		window.location.replace(`${baseUrl}/tokenredirect/${response.data.access_token}/${response.data.refresh_token}`) :
		window.location.replace('/')
		})
	})
}

export const registerSupplier = (formProps, subdomain) => async dispatch => {
	const response = await authApi
		.post(`${API_URL}/auth/register/supplier`, formProps)
		.catch(err => {
			dispatch({
				type: AUTH_ERROR,
				payload:
					'This email address is already registered. Please try logging in.'
			})
		})

	await dispatch(
		setAuth(response.data.access_token, response.data.refresh_token)
	)

	let baseUrl = '';
	if (process.env.REACT_APP_ENVIRONMENT === 'Production') {
		baseUrl = APP_URL_PRODUCTION;
	} else if (process.env.REACT_APP_ENVIRONMENT === 'Local'){
		baseUrl = APP_URL_LOCAL;
	} else {
		baseUrl = APP_URL_DEV;
	}

	dispatch(getUser())
	.then(res => {
		handleHubspotRegisterSupplierForm(formProps.email, formProps.name, formProps.lastname)
		.then(res => {
		//redirecting after login
		//if its a SFA register, send a request to login inside the main domain
			subdomain === 'sfa' ?
			window.location.replace(`${baseUrl}/tokenredirect/${response.data.access_token}/${response.data.refresh_token}`) :
			window.location.replace('/')
		})
	})

}

export const loginSupplier = (props) => async dispatch => {
	const token = props.token

	const response = await authApi
	.post(`${API_URL}/auth/userbytoken?token=${token}}`)
	.catch(err => {
		dispatch({
			type: AUTH_ERROR,
			payload:
			'error trying supplier redirect'
		})
	})

	await dispatch(
		console.log('we got til here')
	)
}

// register generic store user
export const registerStoreUser = (formProps, callback) => async dispatch => {
	let subdomain = window.location.hostname.split('.')[0]

	const response = await authApi
		.post(`${API_URL}/auth/register/${subdomain}`, formProps)
		.catch(err => {
			dispatch({
				type: AUTH_ERROR,
				payload:
					'This email address is already registered. Please try logging in'
			})
		})
	if (response) {
		await dispatch(
			setAuth(response.data.access_token, response.data.refresh_token)
		)
		dispatch(getUser())
		if (callback) callback()
	}
}
// NA - was in InfiniteAisleRegistration
export const registerIABuyer = (formProps, callback) => async dispatch => {

	const response = await authApi
		.post(`${API_URL}/auth/registration`, formProps)
		.catch(err => {
			dispatch({
				type: AUTH_ERROR,
				payload: 'There was a problem registering your store.'
			})
		})

	if (response) {
		await dispatch(
			setAuth(response.data.access_token, response.data.refresh_token)
		)
		dispatch(getUser())
		if (callback) callback()
	}

	// history.push('/')
}
