import {
	GET_UNCONFIRMED_ORDERS,
	ADD_UNCONFIRMED_ORDER,
	DELETE_UNCONFIRMED_ORDER,
	LOCK_ORDERS,
	SET_ORDERS_DATE,
	FETCHING_UORDERS,
	RESET_UNCONFIRMED_ORDERS,
	SET_ACTIVE_ORDERS_STORE
} from '../actions/types'
import { keyBy, omit } from 'lodash'

const INITIAL_STATE = {
	orders: null,
	fetching: false,
	activeOrdersStore: null,
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_UNCONFIRMED_ORDERS:
			return { ...state, orders: keyBy(action.payload, 'hashid') }
		case ADD_UNCONFIRMED_ORDER:
			return {
				...state,
				orders: { ...state.orders, [action.payload.hashid]: action.payload }
			}
		case DELETE_UNCONFIRMED_ORDER:
			return { ...state, orders: omit(state.orders, action.payload) }
		case LOCK_ORDERS:
			let uOrders = { ...state.orders }
			//removed confirmed orders from the list of unconfirmed orders
			action.payload.forEach(hid => {
				delete uOrders[hid]
			})
			return { ...state, orders: uOrders }
		case SET_ORDERS_DATE:
			let updatedOrders = keyBy(action.payload, 'hashid')
			return { ...state, orders: { ...state.orders, ...updatedOrders } }
		case FETCHING_UORDERS:
			return { ...state, fetching: action.payload }
		case RESET_UNCONFIRMED_ORDERS:
			return INITIAL_STATE
		case SET_ACTIVE_ORDERS_STORE:
			return { ...state, activeOrdersStore: action.payload }
		default:
			return state
	}
}
