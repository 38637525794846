import { START_TOUR, END_TOUR } from '../actions/types'

const INITIAL_STATE = {
	tour: null
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case START_TOUR:
			return { ...state, tour: action.payload }
		case END_TOUR:
			return { ...state, tour: action.payload }
		default:
			return state
	}
}
