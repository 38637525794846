const urls = [
	{
		title: 'Navigation',
		items: [
			{
				url: '/',
				icon: 'dashboard',
				title: 'Dashboard',
				id: 'dashboard',
				badge: {
					className: 'badge bg-danger badge-sm badge-outline',
					title: 'New'
				},
				items: []
			},
			{
				url: '',
				icon: '',
				title: 'Orders',
				id: 'orders',
				items: [
					{
						url: '/order/start',
						icon: '',
						title: 'Place New Order',
						items: []
					},
					{
						url: '/orders',
						icon: '',
						title: 'View All Orders',
						items: []
					},
					{
						url: '/orders/upcoming',
						icon: '',
						title: 'View Upcoming Orders',
						items: []
					},
					{
						url: '/orders/historic',
						icon: '',
						title: 'View Past Orders',
						items: []
					}
				]
			},
			// {
			// 	url: '/sample',
			// 	icon: '',
			// 	title: 'Request Samples',
			// 	id: 'sample',
			// 	items: []
			// },
			{
				url: '/products',
				icon: '',
				title: 'Products',
				id: 'products',
				items: []
			},

			{
				url: '/vendors',
				icon: '',
				title: 'Vendors',
				id: 'vendors',
				items: [
					{
						url: '/vendors',
						icon: '',
						title: 'Vendors',
						items: []
					},
					{
						url: '/samplerequests',
						icon: '',
						title: 'Sample Requests',
						items: []
					},
				]
			},
			{
				url: '/invoices',
				icon: '',
				title: 'Invoices',
				id: 'invoices',
				items: []
			},
			{
				url: '/reports',
				icon: '',
				title: 'Reports',
				id: 'reports',
				items: []
			},
			{
				url: '/credits',
				icon: '',
				title: 'Credits',
				id: 'credits',
				items: []
			},
			{
				url: '/stores/manager',
				icon: '',
				title: 'Stores',
				id: 'stores',
				items: [],
				roles: [
					'Operations',
					'Developer',
					'Store',
					'SFP Client',
					'Corporate',
					'District',
					'Specialty Distributor',
					'Analyst'
				]
			}
		]
	}
]
export function navigation(state = Array.from(urls), action) {
	switch (action.type) {
		case 'SET_NAVIGATION':
			return Object.assign({}, state, {
				...action.navigation
			})
		default:
			return state
	}
}
