import { useEffect, useRef, useMemo } from 'react'
import { connect } from 'react-redux'

import { usePrevious } from '../libs/hooks'
import { selectUserAuthenticated, selectUserAdmin } from '../reducers/user'

// MAKE 2 VERSIONS of this - one for callbacks only - no render props
//one for states
//add is admin?

//general render props wrapper for auth, user partner
//callbacks?

//problem here is all changes make rerender children?

function AuthenticatedWrapper(props) {
	const {
		userData,
		isAuthenticated,
		partnerContext,
		auth,
		isAdmin,
		onAuthenticationChange,
		onPartnerChange,
		fetchingUser,
		children
	} = props
	const isFirstRender = useRef(true)
	const prevPartnerHash = usePrevious(partnerContext.hash)
	const prevAuthenticated = usePrevious(isAuthenticated)

	useEffect(() => {
		const authChange = prevAuthenticated !== isAuthenticated
		if (authChange && onAuthenticationChange) {
			onAuthenticationChange(isAuthenticated, userData, partnerContext)
			//console.log('AUTH onAuthenticationChange')
		}
	}, [isAuthenticated])

	useEffect(() => {
		if (
			isAuthenticated &&
			!isFirstRender.current &&
			partnerContext.hash !== prevPartnerHash
		) {
			if (onPartnerChange) {
				onPartnerChange(partnerContext)
				//console.log('AUTH onPartnerChange', partnerContext)
			}
		} else {
			isFirstRender.current = false
		}
	}, [partnerContext.hash, isAuthenticated])

	//improve this
	const isAuthPending = useMemo(() => {
		//console.log("IM CALLED")
		return isAuthenticated && !fetchingUser ? false : Boolean(auth) || fetchingUser
		
	}, [isAuthenticated, auth, fetchingUser])

	return children({
		isAuthenticated,
		isAdmin,
		//auth,
		userData,
		partnerContext,
		isAuthPending,
		//prevAuthenticated
	})
}

const mapStateToProps = state => {
	return {
		auth: state.auth.authenticated,
		userData: state.user.userData,
		isAuthenticated: selectUserAuthenticated(state),
		isAdmin: selectUserAdmin(state),
		fetchingUser: state.user.fetchingUser,
		partnerContext: state.partnerContext
	}
}

export default connect(mapStateToProps, null)(AuthenticatedWrapper)
