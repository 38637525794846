import { HIDE_SCAN_TOOLTIP, SHOW_SCAN_TOOLTIP } from '../actions/types'

const INITIAL_STATE = { showScanTooltip: false }

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case HIDE_SCAN_TOOLTIP:
            return { ...action.payload }
        case SHOW_SCAN_TOOLTIP:
            return { ...action.payload }
        default:
            return state
    }
}