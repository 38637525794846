import React, { Component, Fragment } from 'react'
import { fetchRecords } from 'helpers/Helper'
import { API_URL } from 'helpers/Constants'
import { Link } from 'react-router-dom'
import { navLinkCls, navBadgeCls } from '../helpers'

class NotifySuppliers extends Component {
	constructor(props) {
		super(props)
		this.state = {
			vendorCount: 0
		}
	}

	componentDidMount() {
		this.fetchSuppliersWithOrders()
	}

	fetchSuppliersWithOrders = async () => {
		let options = {
			url: `${API_URL}/admin/suppliers/withorders`
		}
		let res = await fetchRecords(options, this.props.notify)
		if (res !== null) {
			if (res.data.data.length !== 0) {
				this.setState({
					vendorCount: res.data.data.length
				})
			}
		}
	}

	render() {
		return (
			<Fragment>
				<Link to="/admin/orders/notify" className={navLinkCls}>
					{this.state.vendorCount > 0 && (
						<span className={navBadgeCls}>
							{this.state.vendorCount}
						</span>
					)}
					<span className={`fa fa-mail-bulk fa-lg`} />
				</Link>
			</Fragment>
		)
	}
}

export default NotifySuppliers
