import {
	SET_STORED_TABLE_STATE,
	RESET_STORED_TABLE_STATES,
	CLEAR_STORED_TABLE_STATE
} from '../actions/types'
import { isEqual } from 'lodash'

const INITIAL_STATE = {
	last: null
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_STORED_TABLE_STATE:
			const same = isEqual(state.last, action.payload)
			if (same) {
				return state
			}
			return {
				...state,
				[action.payload.tableKey]: action.payload,
				last: action.payload
			}
		case CLEAR_STORED_TABLE_STATE:
			return { ...state, [action.payload]: null }
		case RESET_STORED_TABLE_STATES:
			return INITIAL_STATE
		default:
			return state
	}
}
