import axios from 'axios'
import { addNotification as notify } from 'reapop'
import { API_URL } from '../helpers/Constants'
import { getSessionRefreshToken, getLocalAccessToken, setAllTokens } from './storage.service'
import { clearAuth } from '../actions'
import { store } from '../index'
import { setupInterceptorsTo } from './apiHelpers'

const refreshApi = `${API_URL}/auth/refresh`

const sessionNotification = {
	title: 'Your session has expired',
	message: 'Please re-login to the application',
	status: 'error',
	position: 'tr',
	dismissible: true,
	dismissAfter: 5000
}


export function getApiHeader(xtra = {}) {
	//Header For all API Request
	const token = getLocalAccessToken()
	if (token)
		return {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			...xtra
		}
	else return {}
}

//authenticated will pass intercepted headers etc
const authenticatedInstance = axios.create({
	//baseURL: API_URL
	headers: {
		'Content-Type': 'application/json',
		'X-Requested-With': 'XMLHttpRequest'
	}
})

//unauthenticated - use for auth apis
const unAuthenticatedInstance = axios.create({
	//baseURL: API_URL
	headers: {
		'Content-Type': 'application/json',
		'X-Requested-With': 'XMLHttpRequest'
	}
})

//redux?
//passed from interceptor
const setTokenData = (tokenData = {}, axiosClient) => {
	const { accessToken, refreshToken } = tokenData
  setAllTokens(accessToken, refreshToken)
}

//passed from interceptor to refresh - updates storage/ dispatch / handle error
//expired or just login and notify
const handleTokenRefresh = () => {
	const { dispatch } = store
	const refreshToken = getSessionRefreshToken()
	return new Promise((resolve, reject) => {
		unAuthenticatedInstance
			.post(refreshApi, { refresh_token: refreshToken })
			.then(({ data }) => {
				const tokenData = {
					accessToken: data.access_token,
					refreshToken: data.refresh_token
					//expiresAt: data.expires_at
				}
				resolve(tokenData)
			})
			.catch(err => {
				dispatch(notify(sessionNotification))
				dispatch(clearAuth(true))
				reject(err)
			})
	})
}


setupInterceptorsTo(
	authenticatedInstance,
	{ setTokenData, handleTokenRefresh },
	getLocalAccessToken
)

export { unAuthenticatedInstance as authApi }

const api = authenticatedInstance

export default api
