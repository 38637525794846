import React, { Fragment } from 'react'

const Loadable = ({ loading, Spinner, children, containerStyle }) => {
	return loading ? (
		<div
			className="row justify-content-center align-items-center"
			style={containerStyle}>
			<Spinner
				loading={loading}
				size={20}
				color="blue"
				style={{ marginLeft: 'auto', marginRight: 'auto' }}
			/>
		</div>
	) : (
		<Fragment>{children}</Fragment>
	)
}

export default Loadable
