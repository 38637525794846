//Hubspot Methods

import Axios from "axios";
import { get } from "lodash";
import { HUBSPOT_ACTIVATE_SUPPLIER_FORM_ID, HUBSPOT_API_ID, HUBSPOT_CODE, HUBSPOT_LOGIN_FORM_ID, HUBSPOT_REGISTER_BUYER_FORM_ID, HUBSPOT_REGISTER_SUPPLIER_FORM_ID } from "./Constants";

export const mailDomainVerification = (email) => {
	const blackListDomains = [
		'specialtyfoodpartners.com',
		'sfp.com',
		'freshnation.com',
		'axispoint.com',
		'thefoodpartners.com'
	]
	const emailDomainRegex = /@(.+)|$/;
	let emailDomain = email.match(emailDomainRegex);
	if (!blackListDomains.includes(emailDomain[1])) {
		return true;
	} else {
		return false;
	}

}

const timeoutPromise = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const getIpAddress = async () => {
	const primaryUrl = 'http://ip-api.com/json/'; //uses data.query as IP address
	const alternativeUrl = 'https://geolocation-db.com/json/'; //uses data.IPv4 as IP address
	const defaultIpAddress = '8.8.8.8'; //google IP Address, points to US

	try {
		const primaryResponse = await Promise.race([
			Axios.get(primaryUrl),
			timeoutPromise(10000).then(() => null)
		]);

		if (primaryResponse && primaryResponse.data) {
			return primaryResponse.data.query;
		} else {
			console.log('Primary IP fetch failed or timed out, trying alternative...');
		}

		const alternativeResponse = await Promise.race([
			Axios.get(alternativeUrl),
			timeoutPromise(10000).then(() => null)
		]);

		if (alternativeResponse && alternativeResponse.data) {
			return alternativeResponse.data.IPv4;
		} else {
			console.log('Alternative IP fetch failed or timed out, using default IP address...');
		}

	} catch (error) {
		console.error('Error fetching IP address:', error);
	}

	return defaultIpAddress;
};

const postHubspotLoginForm = (email, ipAddress, hutk) => 
	Axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_CODE}/${HUBSPOT_LOGIN_FORM_ID}`, 
	{
		headers: {
			'Authorization': `Bearer ${HUBSPOT_API_ID}`,
			'Content-Type': 'application/json'
		},
		context: {
			'hutk': hutk,
			'ipAddress': ipAddress
		},
		fields: [{
				"name": "email",
				"value": email,
			  }]
	});

const postHubspotBuyerData = (hutk, ipAddress, email, firstname, lastname) =>
	Axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_CODE}/${HUBSPOT_REGISTER_BUYER_FORM_ID}`,
		{
			headers: {
				'Authorization': `Bearer ${HUBSPOT_API_ID}`,
				'Content-Type': 'application/json'
			},
			context: {
				'hutk': hutk,
				'ipAddress': ipAddress
			},
			fields: [{
				"name": "email",
				"value": email,
			},
			{
				"name": "firstname",
				"value": firstname,
			},
			{
				"name": "lastname",
				"value": lastname,
			}
			]
		}).then((response) => {
			console.log('hubspot auth', response.data);
		}).catch((error) => {
			console.log('hubspot error', error);
		});

const postHubspotSupplierData = (hutk, ipAddress, email, firstname, lastname) =>
	Axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_CODE}/${HUBSPOT_REGISTER_SUPPLIER_FORM_ID}`,
		{
			headers: {
				'Authorization': `Bearer ${HUBSPOT_API_ID}`,
				'Content-Type': 'application/json'
			},
			context: {
				'hutk': hutk,
				'ipAddress': ipAddress
			},
			fields: [{
				"name": "email",
				"value": email
			},
			{
				"name": "firstname",
				"value": firstname
			},
			{
				"name": "lastname",
				"value": lastname
			}]
		}).then((response) => {
			console.log('hubspot auth', response.data);
		}).catch((error) => {
			console.log('hubspot error', error);
		});

export const postHubspotSupplierActivateData = (hutk, ipAddress, email, firstname, lastname) => 
	Axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_CODE}/${HUBSPOT_ACTIVATE_SUPPLIER_FORM_ID}`,
		{
			headers: {
				'Authorization': `Bearer ${HUBSPOT_API_ID}`,
				'Content-Type': 'application/json'
			},
			context: {
				'hutk': hutk,
				'ipAddress': ipAddress
			},
			fields: [{
				"name": "email",
				"value": email
			},
			{
				"name": "firstname",
				"value": firstname
			},
			{
				"name": "lastname",
				"value": lastname
			}]
		}).then((response) => {
			console.log('hubspot auth', response.data);
			return response;
		}).catch((error) => {
			console.log('hubspot error', error);
		});

export const handleHubspotLoginForm = async (email) => {

	if (mailDomainVerification(email)){
		//grab Hubspot Cookie
		let hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1")
		//fetch the user's IP Address
		const ipAddress = await getIpAddress()

		postHubspotLoginForm(email, ipAddress, hutk)
			.then((response) => {
				console.log('hubspot auth', response.data);
			})
			.catch((error) => {
				console.log('hubspot error', error);
			})

	} else {
		console.log("It's an internal email");
		return
	};
}



export const handleHubspotRegisterSupplierForm = async (email, firstname, lastname) => {
	let hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1")
	if (mailDomainVerification(email)) {
		const ipAddress = await getIpAddress()

		await postHubspotSupplierData(hutk, ipAddress, email, firstname, lastname)
	} else {
		return;
	}
}

export const handleHubspotRegisterBuyerForm = async (email, firstname, lastname) => {
	let hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1")
	if (mailDomainVerification(email)) {
		const ipAddress = await getIpAddress()

		await postHubspotBuyerData(hutk, ipAddress, email, firstname, lastname)
	} else {
		return;
	}
}

export const handleHubspotSupplierActivateForm = async (email, firstname, lastname) => {
	let hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1")
	if (mailDomainVerification(email)) {
		const ipAddress = await getIpAddress()
		await postHubspotSupplierActivateData(hutk, ipAddress, email, firstname, lastname)
	} else {
		return;
	}
}


