import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { authenticatedComposer } from '../provider/authenticatedComposer'
import CustomRoute from './CustomRoute'

export const defaultUnauthorizedRoute = '/login'
export const defaultAuthorizedRoute = '/'

/* generatePath("/user/:id/:entity(posts|comments)", {
  id: 1,
  entity: "posts"
}); */
// Will return /user/1/post
//make more generic
export function getRedirect(redirectTo, params) {
	if (redirectTo.path) {
		return params
			? `${redirectTo.path}${params[redirectTo.paramsMatch] || ''}`
			: redirectTo.path
	}
	return redirectTo
}

export function getPathFromState(state, defaultPath = defaultAuthorizedRoute) {
	if (!state || !state.from) return defaultPath
	const { pathname, search = '' } = state.from
	return pathname + search
}

//https://github.com/remix-run/react-router/issues/4105#issuecomment-289195202

export function renderMergedProps(component, ...rest) {
	const finalProps = Object.assign({}, ...rest)
	return React.createElement(component, finalProps)
}

/* <Switch>
      <PropsRoute path='/login' component={Login} auth={auth} authenticatedRedirect="/" />
      <PropsRoute path='/allbooks' component={Books} booksGetter={getAllBooks} />
      <PropsRoute path='/mybooks' component={Books} booksGetter={getMyBooks} />
      <PropsRoute path='/trades' component={Trades} user={user} />
    </Switch> */

export function PropsRoute({ component, ...rest }) {
	return (
		<Route
			{...rest}
			render={routeProps => {
				return renderMergedProps(component, routeProps, rest)
			}}
		/>
	)
}
//MAKE THESE MORE CONFIGURABLE CONDITIONS?
// let match = useRouteMatch("/blog/:slug");
//add any checks on user her for routes\
//this should be history
export function checkUserLocation(location, user) {
	if (
		user &&
		[0, 2].includes(user.status) &&
		location.pathname !== '/account-status'
	) {
		return <Redirect to="/account-status" />
	}
	return true
}

export function CheckUser({ children, isCheckUser, location, user }) {
	if (
		isCheckUser &&
		user &&
		[0, 2].includes(user.status) &&
		location.pathname !== '/account-status'
	) {
		return <Redirect to="/account-status" />
	}
	return children
}

// Private routes with redirects and check user
//check for other route specific not need in component
//MOVE CHECK USER ELSEWHERE
export function PrivatePropsRoute({
	component,
	redirectTo = defaultUnauthorizedRoute,
	isCheckUser = true,
	isAuthenticated,
	userData,
	...rest
}) {
	return (
		<CustomRoute
			user={userData}
			{...rest}
			render={routeProps => {
				// console.log(redirectTo, routeProps)
				return isAuthenticated ? (
					<CheckUser
						user={userData}
						isCheckUser={isCheckUser}
						location={routeProps.location}>
						{renderMergedProps(component, routeProps, rest)}
					</CheckUser>
				) : (
					<Redirect
						to={{
							pathname: getRedirect(redirectTo, routeProps.match.params),
							state: { from: routeProps.location }
						}}
					/>
				)
			}}
		/>
	)
}
//need to determine how to redirect only on route not logging in
//login push happens after redirect here
//with layout inside route or wrapped before?
//condition?
export function PublicPropsRoute({
	component,
	redirectTo = defaultAuthorizedRoute,
	isRedirectOnLogin = false,
	isAuthenticated,
	userActive,
	isRestricted = true,
	layout: LayoutComponent,
	...rest
}) {
	return (
		<Route
			{...rest}
			render={routeProps => {
				const redirectPath = getRedirect(redirectTo, routeProps.match.params)
				return userActive && isAuthenticated && isRestricted ? (
					<Redirect
						to={{
							pathname: redirectPath
							//state: { from: routeProps.location }
						}}
					/>
				) : (
					<LayoutComponent
						redirectPath={isRedirectOnLogin ? redirectPath : null}>
						{renderMergedProps(component, routeProps, rest)}
					</LayoutComponent>
				)
			}}
		/>
	)
}

const authConnector = authenticatedComposer()

export const PrivateConnectedRoute = authConnector(PrivatePropsRoute)
export const PublicConnectedRoute = authConnector(PublicPropsRoute)
