import {
	GET_USER,
	GET_USER_PERMISSIONS,
	FETCHING_USER,
	USER_ACTIVE,
	SET_SAMPLE_REQUEST_COOLDOWN,
} from '../actions/types'
import { ADMIN_ROLES } from '../helpers/Constants'

const INITIAL_STATE = {
	userData: null,
	permissions: null,
	fetchingUser: false,
	userActive: false //user authenticated and active layout
}

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_USER:
			return { ...state, userData: action.payload }
		case GET_USER_PERMISSIONS:
			return { ...state, permisssions: action.payload }
		case FETCHING_USER:
			return { ...state, fetchingUser: action.payload }
		case USER_ACTIVE:
			return { ...state, userActive: action.payload }
		case SET_SAMPLE_REQUEST_COOLDOWN:
			return {
				...state,
				userData: {
					...state.userData,
					vendor_sample_request_cooldown: action.payload
				}
			};
		default:
			return state
	}
}

export const selectUserName = state =>
	state.user.userData ? state.user.userData.name : null

// fully authenticated: token and user
export const selectUserAuthenticated = state =>
	Boolean(state.auth.authenticated && state.user.userData)

// user isAdmin
export function selectUserAdmin(state, xtraRoles = []) {
	if (state.user.userData) {
		return ADMIN_ROLES.concat(xtraRoles).includes(state.user.userData.role_name)
	}
	return false
}
