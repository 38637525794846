import axios from 'axios'
import _ from 'lodash'
import numeral from 'numeral'
import { store } from '../index'
//import { AUTH_USER, AUTH_EXPIRATION } from 'actions/types'
import { addNotification as notify } from 'reapop'
import api, { getApiHeader } from '../api/api'

//simplifying to use api instead with interceptors
//todo get rid of cancel token; install new axios, no notify
//error handling should be in instance or in react query
// TODO set header automatically in interceptor?
export function getHeader(xtra) {
	return getApiHeader(xtra)
}

//need to deal with as instance
const CancelToken = axios.CancelToken
//instance.CancelToken = axios.CancelToken;
//instance.isCancel = axios.isCancel;

export function getCancelToken() {
	return CancelToken.source()
}

export function capitalize(str) {
	return str[0].toUpperCase() + str.substr(1)
}

export const fetchPDF = async (
	{ url },
	shouldNotify,
	type = null,
	responseType = 'blob'
) => {
	let headers = getHeader()
	let method = 'get'
	if (type === 'file') {
		headers['Content-Type'] = 'application/form-data'
		// headers['Accept'] = 'application/pdf'
	} else if (type === 'pdf') {
		headers['Content-Type'] = 'application/pdf'
		headers['Accept'] = 'application/pdf'
	}

	let res = []
	try {
		res = await api({
			url,
			method,
			headers,
			responseType
		})
	} catch (err) {
		if (err && err.response && err.response.status === 400) {
			store.dispatch(
				notify({
					title: "We're sorry!",
					message: err.response.headers['custom-error-header'],
					status: 'error',
					position: 'bc',
					dismissible: true,
					dismissAfter: 0,
					closeButton: false
				})
			)
			return null
		}
		if (shouldNotify && process.env.REACT_APP_ENVIRONMENT !== 'Production') {
			store.dispatch(
				notify({
					title: 'Error',
					message: `Opps!!! Something went wrong.`,
					status: 'error',
					position: 'bc',
					dismissible: true,
					dismissAfter: 0,
					closeButton: false
				})
			)
		}

		return null
	}

	return res
}

export const fetchRecords = async (
	{ url },
	shouldNotify,
	type = null,
	responseType = null,
	cancelToken = null
) => {
	let headers = getHeader()
	let method = 'get'
	if (type === 'file') {
		headers['Content-Type'] = 'application/form-data'
		// headers['Accept'] = 'application/pdf'
	} else if (type === 'pdf') {
		headers['Content-Type'] = 'application/pdf'
		headers['Accept'] = 'application/pdf'
	}
	//console.log("API FET RECORDS", api.defaults)
	let res = []
	try {
		if (cancelToken === null) {
			res = await api({
				url,
				method,
				headers,
				responseType
			})
		} else {
			res = await api({
				url,
				method,
				headers,
				responseType,
				cancelToken: cancelToken.token
			})
		}
	} catch (err) {
		// Handling cancelled requests
		if (axios.isCancel(err)) {
			console.warn('Request cancelled.', err)
			return null
		}

		if (shouldNotify && process.env.REACT_APP_ENVIRONMENT !== 'Production') {
			store.dispatch(
				notify({
					title: 'Error',
					message: `Opps!!! Something went wrong.`,
					status: 'error',
					position: 'bc',
					dismissible: true,
					dismissAfter: 0,
					closeButton: true
				})
			)
		}
		//throw err

		return null
	}

	return res
}

export const postData = async (
	{ url, method, data },
	shouldNotify,
	type = null,
	responseType = null
) => {
	let headers = getHeader()
	if (type === 'file') {
		headers['Content-Type'] = 'multipart/form-data'
	}
	let res = []
	try {
		res = await api({
			url,
			method,
			responseType,
			headers,
			data
		})
	} catch (err) {
		const eResponse = err && err.response ? err.response : false
		//console.log("POST Data error", err, eResponse, shouldNotify)
		let errorMessage =
			eResponse && eResponse.data
				? _.map(eResponse.data.errors, function (e) {
						return e
				  }).join(' ')
				: 'Oops. Something went wrong on our end'

		if (eResponse && eResponse.status === 500) {
			errorMessage =
				'Oops. Something went wrong on our end. The Specialty Food Partners team has been alerted and is looking into the issue.'
		} else {
			console.log(err)
			console.log(eResponse)
			errorMessage =
				eResponse && eResponse.status === 422
					? errorMessage
					: 'Hmm. Something went wrong on our end. The Specialty Food Partners team has been alerted and is looking into the issue.'
			if(eResponse && eResponse.status === 422) {
				return eResponse 
			}

		}

		if (shouldNotify && process.env.REACT_APP_ENVIRONMENT !== 'Production') {
			store.dispatch(
				notify({
					title: 'Error',
					message: errorMessage,
					status: 'error',
					position: 'bc',
					dismissible: true,
					dismissAfter: 0,
					closeButton: false
				})
			)
		}

		return null
	}

	return res
}

export const deleteRecord = async ({ url }, notify) => {
	let headers = getHeader()
	let method = 'delete'
	let res = []
	try {
		res = await api({
			url,
			method,
			headers
		})
	} catch (err) {
		if (notify && process.env.REACT_APP_ENVIRONMENT !== 'Production') {
			notify({
				title: 'Error',
				message: `Opps!!! Something went wrong.`,
				status: 'error',
				position: 'bc',
				dismissible: true,
				dismissAfter: 0,
				closeButton: false
			})
		}

		return null
	}

	return res
}

export const currency = val => {
	if (val !== undefined && val !== null) {
		return numeral(val).format('$0,0.00')
	}
	return ''
}

export const generateCrumb = (path, crumb = false) => {
	if (!crumb) {
		let crumbObject = []

		let nodes = path.slice(1).split('/')

		let nodesLength = nodes.length

		for (let i = 0; i < nodesLength; i++) {
			let link = `/${nodes.slice(0, i + 1).join('/')}`
            nodes[i] = nodes[i].replace(/%2F/g, '/')
			crumbObject.push({
				label: nodes[i],
				isLink: i < nodesLength - 1,
				link
			})
		}
		return crumbObject
	}
}

export const calculatePricing = (props, state) => {
	const { change, vals } = props

	let total_purchase_price =
		vals.sell_price - (Number(vals.fn_fee) / 100) * vals.sell_price

	let unit_purchase_price =
		vals.case_pack_size === 0 ? 0 : total_purchase_price / vals.case_pack_size

	let unit_sell_price =
		vals.case_pack_size === 0 ? 0 : vals.sell_price / vals.case_pack_size

	//let unit_delivery_cost = vals.case_pack_size === 0 ? 0 : Number(vals.case_delivery_cost) / vals.case_pack_size
	if (unit_sell_price.toFixed(2) !== 'NaN') {
		change('unit_sell_price', unit_sell_price.toFixed(2))
	}
	if (unit_purchase_price.toFixed(2) !== 'NaN') {
		change('unit_purchase_price', unit_purchase_price.toFixed(2))
	}
	//change('unit_delivery_cost', unit_delivery_cost.toFixed(2))
	if (total_purchase_price.toFixed(2) !== 'NaN') {
		change('purchase_price', total_purchase_price.toFixed(2))
	}
}

export const calculateSpecialityPricing = (props, state) => {
	const { change, vals, pricing } = props
	let markup_base = NaN

	if (
		typeof vals.specialty_base_purchase_price !== 'undefined' &&
		typeof vals.specialty_case_delivery_cost !== 'undefined'
	) {
		markup_base =
			Number(vals.specialty_base_purchase_price) +
			Number(vals.specialty_case_delivery_cost)
	}

	let sp_fn_markup = 5.0
	let sp_specialty_markup = 5.0

	if (pricing) {
		if (pricing.partner) {
			sp_fn_markup =
				pricing.partner.fn_markup !== '0.00' ? pricing.partner.fn_markup : 5.0
			sp_specialty_markup =
				pricing.partner.speacilty_client_markup !== '0.00'
					? pricing.partner.specialty_client_markup
					: 5.0
		}
	} else {
		if (state.partner) {
			sp_fn_markup =
				state.partner.fn_markup !== '0.00' ? state.partner.fn_markup : 5.0
			sp_specialty_markup =
				state.partner.specialty_client_markup !== '0.00'
					? state.partner.specialty_client_markup
					: 5.0
		}
	}
	let specialty_client_price =
		Number(vals.specialty_base_purchase_price) * (1 + sp_fn_markup / 100) +
		Number(vals.specialty_case_delivery_cost)

	let specialty_sell_price =
		specialty_client_price * (1 + sp_specialty_markup / 100)

	if (markup_base.toFixed(2) !== 'NaN') {
		change('markup_base', markup_base.toFixed(2))
	}

	if (specialty_client_price.toFixed(2) !== 'NaN') {
		change('specialty_client_price', specialty_client_price.toFixed(2))
	}

	let specialty_store_margin =
		(vals.specialty_msrp - specialty_sell_price / vals.case_pack_size) /
		vals.specialty_msrp
	if (specialty_store_margin.toFixed(2) !== 'NaN') {
		change('specialty_store_margin', specialty_store_margin.toFixed(2))
	}
	if (specialty_sell_price.toFixed(2) !== 'NaN') {
		change('specialty_sell_price', specialty_sell_price.toFixed(2))
	}

	// change('markup_base', )
	//do stuff
}

export const calculateShippingPricing = props => {
	const { change, vals } = props

	let unit_delivery_cost =
		vals.case_pack_size === 0
			? 0
			: Number(vals.case_delivery_cost) / vals.case_pack_size

	let total_purchase_price =
		vals.sell_price -
		(Number(vals.fn_shipping_rate) / 100) * vals.sell_price -
		vals.case_delivery_cost

	let unit_purchase_price =
		vals.case_pack_size === 0 ? 0 : total_purchase_price / vals.case_pack_size

	let unit_sell_price =
		vals.case_pack_size === 0 ? 0 : vals.sell_price / vals.case_pack_size
	if (unit_sell_price.toFixed(2) !== 'NaN') {
		change('unit_sell_price', unit_sell_price.toFixed(2))
	}
	if (unit_purchase_price.toFixed(2) !== 'NaN') {
		change('unit_shipping_purchase_price', unit_purchase_price.toFixed(2))
	}
	if (unit_delivery_cost.toFixed(2) !== 'NaN') {
		change('unit_delivery_cost', unit_delivery_cost.toFixed(2))
	}
	if (total_purchase_price.toFixed(2) !== 'NaN') {
		change('fn_shipping_purchase_price', total_purchase_price.toFixed(2))
	}
}
