import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import {values, sumBy} from 'lodash'
import { Dropdown } from 'antd'
import CartOrders from './CartOrders'
import { navLinkCls, navBadgeCls } from '../helpers'

const CartDropdown = props => {
	const { className, dropdownClassName, orderItemsCount, isMobile } = props
	const [showCart, setShowCart] = useState(false)

	const closeCart = useCallback(() => {
		setShowCart(false)
	})

	const handleVisibleChange = (vis) => {
		setShowCart(vis)
	}

  const cartBody = <CartOrders closeCart={closeCart} contentClass={className} dropdownClassName={dropdownClassName} isMobile={isMobile}/>
	return (
		<Dropdown overlay={cartBody} trigger={['click']} placement={isMobile ? "bottomLeft" : "bottom"} visible={showCart} onVisibleChange={handleVisibleChange}>
			<a className={navLinkCls}>
				{orderItemsCount > 0 && (
					<span className={navBadgeCls}>
						{orderItemsCount}
					</span>
				)}
				<span className={`fa fa-shopping-cart fa-lg`} />
			</a>
			
		</Dropdown>
	)
}

const mapStateToProps = (state) => {
	const { unconfirmedOrders } = state
	return {
		orderItemsCount: sumBy(values(unconfirmedOrders.orders), o => Number(o.qty))
	}
}

export default connect(mapStateToProps)(CartDropdown)