import {
	GET_PARTNERS,
	RESET_PARTNERS,
	FETCHING_PARTNERS
} from '../actions/types'

const INITIAL_STATE = {
	partners: null,
	fetchingPartners: false
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_PARTNERS:
			return { ...state, partners: action.payload }
		case FETCHING_PARTNERS:
			return { ...state, fetchingPartners: action.payload }
		case RESET_PARTNERS:
			return INITIAL_STATE
		default:
			return state
	}
}
