import { QUEUE_PUSH, QUEUE_REMOVE } from '../actions/types'
import _ from 'lodash'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case QUEUE_PUSH:
			return { ...state, [action.payload.id]: action.payload }
		case QUEUE_REMOVE:
			return _.omit(state, action.payload)
		default:
			return state
	}
}
