
import {TOGGLE_FAVORITE_FILTER} from "../actions/types";


export default function(state = false, action) {
	switch (action.type) {
		case TOGGLE_FAVORITE_FILTER:
			return !state
		default:
			return state
	}
}
