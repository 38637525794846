import { get } from 'lodash'
import {
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
	useCallback,
	useReducer
} from 'react'

//common optimization hooks

//check change on obj - key - callback with key/prop
/* const { keyChange } = useObjChange(
  location,
  'key',
  'state.store_hash',
  checkStoreChange
) */
export const useObjChange = (obj, key, prop, action) => {
	const keyChange = get(obj, key, false)
	const propChange = get(obj, prop, false)

	useEffect(() => {
		if(action) action(propChange, keyChange)
	}, [keyChange])

	return {
		keyChange,
		propChange
	}
}

//memo factory wrapper
//const useMemoProduct = createMemo(someExpensive function);//return function
//const { productTableData, productTagsData, productAssets, isAssetsActive } = useMemoProduct(product)
export const createMemo =
	fn =>
	(...args) =>
		useMemo(() => fn(...args), args)

//prevent rerenders of children from parent updates - when passing callbacks as props
//https://github.com/samanmohamadi/use-memoized-callback
export function useMemoizedCallback(cb) {
	const data = useRef({ callback: null, handler: null })
	data.current.callback = cb
	if (!data.current.handler) {
		data.current.handler = (...args) => data.current.callback(...args)
	}
	return data.current.handler
}

//useState with memoized callback access return in Setter (like classes)
//const [state, setState] = useStateCallback(0); // same API as useState
// second argument is callback, `s` being the *updated* state
//setState( prev => prev + 1,  s => console.log("I am called after setState, state:", s));
export function useStateCallback(initialState) {
	const [state, setState] = useState(initialState)
	const cbRef = useRef(null) // init mutable ref container for callbacks

	const setStateCallback = useCallback((state, cb) => {
		cbRef.current = cb // store current, passed callback in ref
		setState(state)
	}, []) // keep object reference stable, exactly like `useState`

	useEffect(() => {
		// cb.current is `null` on initial render,
		// so we only invoke callback on state *updates*
		if (cbRef.current) {
			cbRef.current(state)
			cbRef.current = null // reset callback after execution
		}
	}, [state])

	return [state, setStateCallback]
}

//gets the previous value props/state
//https://usehooks.com/usePrevious/
export function usePrevious(value) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef()
	// Store current value in ref
	useEffect(() => {
		ref.current = value
	}, [value]) // Only re-run if value changes
	// Return previous value (happens before update in useEffect above)
	return ref.current
}

export const useEnhancedReducer = (reducer, initState, initializer) => {
	const lastState = useRef(initState)
	const getState = useCallback(() => lastState.current, [])
	return [
		...useReducer(
			(state, action) => (lastState.current = reducer(state, action)),
			initState,
			initializer
		),
		getState
	]
}

 // Create a getter for the instance (helps avoid a lot of potential memory leaks)
 //const getInstance = useGetLatest(instanceRef.current)

export function useGetLatest(obj) {
  const ref = useRef()
  ref.current = obj

  return useCallback(() => ref.current, [])
}

/* const objFinal = useMemoCompare(obj, (prev, next) => {
	return prev && prev.id === next.id;
}); */
export function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}

export function useClientRect() {
	const [rect, setRect] = useState(null)
	const ref = useCallback(node => {
		if (node !== null) {
			setRect(node.getBoundingClientRect())
		}
	}, [])
	return [rect, ref]
}

export function useIsOverflow(ref, callback) {
	const [isOverflow, setIsOverflow] = useState(undefined)

	useLayoutEffect(() => {
		const { current } = ref

		const trigger = () => {
			const hasOverflow = current.scrollHeight > current.clientHeight

			setIsOverflow(hasOverflow)

			if (callback)
				callback(hasOverflow)
		}

		if (current) {
			if ('ResizeObserver' in window) {
				new ResizeObserver(trigger).observe(current)
			}

			trigger()
		}
	}, [callback, ref])

	return isOverflow
}
