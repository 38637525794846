import React, {Fragment, useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { navLinkCls, navBadgeCls } from '../helpers'
import { Tooltip } from 'antd'
import { hideToolTip } from '../../actions/tooltip'


const ScanItem = props => {
	const { showScanTooltip, hideToolTip } = props
	// logging in sets the initial default open state to true
	// closing it resets the default open state to false (until the user logs in again)
	const handleInitialClose = (e) => {
		if(e === false) {
			if(showScanTooltip === true) {
				hideToolTip()
			}
		}
	}
	return (
		<Fragment>
			<Link to="/barcode-scan" className={navLinkCls}>
				<Tooltip 
					title="Search products by scanning item barcodes with your device's camera" 
					placement="bottomRight" 
					arrowPointAtCenter={true} 
					defaultOpen={showScanTooltip}
					onOpenChange={handleInitialClose}
					key={showScanTooltip}>
					<span className={navBadgeCls}>!</span>
					<span className={`fa fa-barcode fa-lg`} />
				</Tooltip>
			</Link>
		</Fragment>
	)
}

const mapDispatchToProps = {
	hideToolTip
}

const mapStateToProps = state => {
	return {
		showScanTooltip: state.tooltip.showScanTooltip
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanItem)
