import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { map as lMap, compact } from 'lodash'
import landingRoutes from './landingRoutes'
import mainRoutes from './mainRoutes'
import LandingLayout from '../layouts/landing'
import MainLayout from '../layouts/main'
import ErrorPage from '../pages/error-page/ErrorPage'
import { PrivateConnectedRoute, PublicConnectedRoute } from './routeHelpers'

//return main routes
function useMainRoutes() {
	const mainPropsRoutes = mainRoutes.map(privateRouteProps => {
		const { key, ...routeProps } = privateRouteProps
		return <PrivateConnectedRoute key={key} {...routeProps} />
	})
	//Memoize
	return {
		mainPropsRoutes
	}
}
//NA dont need role check in new versions
// if not authenticated return? until ready
//check user roles, suppliers? separate. admin - sepparate nest route?
function useMainRoutesRoles(userData) {
	//filter mainRoutes for roles ,etc, contect (supplier)
	//const mainPaths = mainRoutes.map(r => r.path)
	//THIS NEEDS TO HANDLE a generic protected route if no user data - exact false
	const mainPropsRoutes = useMemo(() => {
		if (!userData) {
			const { key, ...routeProps } = mainRoutes[0]
			return <PrivateConnectedRoute key={key} {...routeProps} exact={false} />
		}
		//filter/compact?
		const retRoutes = lMap(mainRoutes, rt => {
			const { key, roles, ...routeProps } = rt
			if (roles && !roles.includes(userData.role_name)) {
				return null
			}
			return <PrivateConnectedRoute key={key} {...routeProps} />
		})
		return compact(retRoutes)
	}, [userData])

	//Memoize
	return {
		//mainPaths,
		mainPropsRoutes
	}
}

function useLandingRoutes() {
	//filter landing Routes for contect (supplier), layouts?
	const landingPaths = landingRoutes.map(r => r.path)
	const landingPropsRoutes = landingRoutes.map(publicRouteProps => {
		//specific layout routes?
		const { key, ...routeProps } = publicRouteProps
		return <PublicConnectedRoute key={key} {...routeProps} />
	})
	//Memoize
	return {
		landingPaths,
		landingPropsRoutes
	}
}

function AppRoutes({ subdomainClass, isInitializing }) {
	const { landingPaths, landingPropsRoutes } = useLandingRoutes()
	const { mainPropsRoutes } = useMainRoutes()
	if (isInitializing) {
		return (
			<div
				style={{
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
				<h2>Initializing...</h2>
			</div>
		)
	}
	return (
		<Switch>
			<Route exact path={landingPaths}>
				<LandingLayout>
					<Switch>
						{landingPropsRoutes}
					</Switch>
				</LandingLayout>
			</Route>
			<Route path="*">
				<Route exact path="/account-status">
					<LandingLayout className="d-flex align-items-center">
						{mainPropsRoutes.filter(r => r.key === "account-status")}
					</LandingLayout>
				</Route>
				<MainLayout subdomainClass={subdomainClass}>
					<Switch>
						{mainPropsRoutes.filter(r => r.key !== "account-status")}
						<Route component={ErrorPage} />
					</Switch>
				</MainLayout>
			</Route>
		</Switch>
	)
}

export default AppRoutes
