import React, { Suspense } from 'react'
import clsx from 'clsx'
import './landing.scss'

const baseLandingCls = 'overflow-auto w-100 h-100'

// basis for landing pages
// use wrappers for specific
// need sub domain?
// config routes with layout? wrappers
// check authenticated and redirect?

export default function LandingLayout({ children, className }) {
	const retCls = clsx(baseLandingCls, className)
	return (
		<div className={retCls}>
			<Suspense
				fallback={
					<div className="d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle">
						<h4>Page is Loading...</h4>
					</div>
				}>
				{children}
			</Suspense>
		</div>
	)
}
