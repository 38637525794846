import React from 'react'
import StagingHazard from './StagingHazard'
import DownloadQueue from './download-queue'
import Navbar from './navbar'
import TopNavigation from './top-navigation'
import MobileNav from './mobile-nav'

const Header = ({ toggleDrawer }) => {

	return (
		<header>
			<DownloadQueue />
			<Navbar toggleDrawer={toggleDrawer} isStart={false} />
			<TopNavigation />
			<MobileNav />
			<StagingHazard />
		</header>
	)
}

export default Header
