import { AUTH_USER, AUTH_ERROR, AUTH_EXPIRATION } from '../actions/types'

const INITIAL_STATE = {
	authenticated: '',
	errorMessage: '',
	expired: null
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_USER:
			return {
				...state,
				...{ authenticated: action.payload, errorMessage: '' }
			}
		case AUTH_ERROR:
			return { ...state, errorMessage: action.payload }
		case AUTH_EXPIRATION:
			return { ...state, expired: action.payload }
		default:
			return state
	}
}
