import React, { Component } from 'react'
import { fetchRecords } from 'helpers/Helper'
import { API_URL } from 'helpers/Constants'
import fileDownload from 'js-file-download'

import _ from 'lodash'

class RecentUsers extends Component {
	constructor(props) {
		super(props)
		this.state = {
			recentUsers: null
		}
	}

	componentDidMount() {
		this.fetchLogins()
	}

	exportRecentUsers = async () => {
		let options = {
			url: `${API_URL}/admin/exportrecentusers`
		}
		let res = await fetchRecords(options, this.props.notify)
		if (res !== null) {
			fileDownload(res.data, res.headers['file-name'])
		}
	}

	fetchLogins = async () => {
		let options = {
			url: `${API_URL}/admin/recentusers`
		}
		let res = await fetchRecords(options, this.props.notify)
		if (res !== null) {
			this.setState({
				recentUsers: res.data.data,
				loading: false
			})
		}
	}

	recentUsers = () => {
		const { recentUsers } = this.state
		const userLength = recentUsers !== null ? recentUsers.length : 0
		return (
			<React.Fragment>
				{_.map(recentUsers, (user, index) => (
					<React.Fragment key={index}>
						<li className="media">
							<div className="media-left">
								<img
									className="media-object img-circle"
									src={user.profile_image}
									alt=""
								/>
							</div>
							<div className="media-body" style={{ marginLeft: '20px' }}>
								<h6 className="media-heading nomargin">{user.causer_name}</h6>
								{user.company_name}
								<small className="date">
									<i className="glyphicon glyphicon-time" /> {user.created_at}
								</small>
							</div>
						</li>
						{userLength !== index + 1 && <hr />}
					</React.Fragment>
				))}
			</React.Fragment>
		)
	}

	render() {
		return (
			<div className="card">
				<div className="card-body" style={{ padding: '0px' }}>
					<ul className="media-list user-list">{this.recentUsers()}</ul>
				</div>
				<div className="card-footer nopadding">
					<span
						className="btn btn-success btn-block"
						onClick={this.exportRecentUsers}>
						Export Logins <i className="fa fa-download" />
					</span>
				</div>
			</div>
		)
	}
}
export default RecentUsers
