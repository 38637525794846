//STORAGE SERVICE local/session
//tokens/partner


const localAccess = 'raiden_token'
const localPartner = 'partner_context'
const sessionRefresh = 'raiden_refresh'

const partnerContextEmpty = {
	type: null,
	hash: null,
	name: null
}


// REFRESH TOKEN
const getSessionRefreshToken = () => {
	return sessionStorage.getItem(sessionRefresh)
}
const updateSessionRefreshToken = token => {
	if (token) {
		sessionStorage.setItem(sessionRefresh, token)
	}
}

const removeSessionRefreshToken = () => {
	sessionStorage.removeItem(sessionRefresh)
}

// ACCESS TOKEN
const getLocalAccessToken = () => {
	return localStorage.getItem(localAccess)
}

const updateLocalAccessToken = token => {
	if (token) {
		localStorage.setItem(localAccess, token)
	}
}

const removeLocalAccessToken = () => {
	localStorage.removeItem(localAccess)
}

// PARTNER Context - Object
const getLocalPartnerContext = (defaultEmpty = false) => {
	const partner = localStorage.getItem(localPartner)
  if (partner !== null) {
    return JSON.parse(partner)
  }
  return defaultEmpty ? partnerContextEmpty : null
}

const updateLocalPartnerContext = (context, defaultEmpty = true) => {
  const shouldSet = context || defaultEmpty
	if (shouldSet) {
    const toSet = context || partnerContextEmpty
		localStorage.setItem(localPartner, JSON.stringify(toSet))
	}
}

const removeLocalPartnerContext = () => {
	localStorage.removeItem(localPartner)
}

// ALL TOKENS
const getAllTokens = () => {
	return {
		accessToken: getLocalAccessToken(),
		refreshToken: getSessionRefreshToken()
	}
}

const setAllTokens = (access, refresh) => {
	updateLocalAccessToken(access)
	updateSessionRefreshToken(refresh)
}

const removeAllTokens = (isPartner = false) => {
	removeLocalAccessToken()
	removeSessionRefreshToken()
	if (isPartner) {
		removeLocalPartnerContext()
	}
}

export {
	getSessionRefreshToken,
	updateSessionRefreshToken,
	removeSessionRefreshToken,
	removeLocalAccessToken,
	getLocalAccessToken,
	updateLocalAccessToken,
  getLocalPartnerContext,
  updateLocalPartnerContext,
  removeLocalPartnerContext,
	getAllTokens,
	setAllTokens,
	removeAllTokens
}
