import React, { Component, Fragment } from 'react'
import * as atatus from 'atatus-js'
import { withRouter } from 'react-router-dom'

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
		atatus.notify(error)
		// Display fallback UI
		this.setState({ hasError: true })
	}

	backToSafety = () => {
		this.setState({ hasError: false }, () => {
			this.props.history && this.props.history.goBack()
		})
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Fragment>
					<div
						className="error-page text-center text-danger  col-md-12"
						style={{ height: '100%' }}>
						<i className="fas fa-exclamation fa-10x" />
						<h4 className="text-danger">We're sorry.</h4>
						<p>
							We seem to have gotten our wires crossed. Please refresh the page
							and try again.
						</p>
						<span
							className="link-primary btn btn-link"
							onClick={this.backToSafety}>
							Click here to go back
						</span>
					</div>
				</Fragment>
			)
		}
		return this.props.children
	}
}

export default withRouter(ErrorBoundary)
