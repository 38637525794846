//Auth
export const AUTH_USER = 'auth_user'
export const AUTH_ERROR = 'auth_error'
export const AUTH_EXPIRATION = 'auth_expiration'

//User
export const GET_USER = 'get_user'
// eslint-disable-next-line
export const GET_USER_PERMISSIONS = 'get_user_permission'
export const FETCHING_USER = 'fetching_user'
export const USER_ACTIVE = 'user_active'
export const SET_SAMPLE_REQUEST_COOLDOWN = 'set_sample_request_cooldown'

// Tooltip default show (for scanner)
export const HIDE_SCAN_TOOLTIP = 'hide_scan_tooltip'
export const SHOW_SCAN_TOOLTIP = 'show_scan_tooltip'

//Modal
export const TOGGLE_MODAL = 'toggle_modal'
export const SET_MODAL_BODY = 'set_modal_body'

//PartnerGroup
export const GET_PARTNER_GROUPS = 'get_partner_groups'
export const RESET_PARTNER_GROUPS = 'reset_partner_groups'
export const FETCHING_GROUPS = 'fetching_groups'

//Partner
export const GET_PARTNERS = 'get_partners'
export const RESET_PARTNERS = 'reset_partners'
export const FETCHING_PARTNERS = 'fetching_partners'

//PartnerContex
export const SET_CONTEXT = 'set_context'
export const RESET_CONTEXT = 'reset_context'

//Unconfirmed Orders
export const GET_UNCONFIRMED_ORDERS = 'get_unconfirmed_orders'
export const ADD_UNCONFIRMED_ORDER = 'add_unconfirmed_order'
export const DELETE_UNCONFIRMED_ORDER = 'delete_unconfirmed_order'
export const LOCK_ORDERS = 'confirm_orders'
export const SET_ORDERS_DATE = 'set_orders_date'
export const FETCHING_UORDERS = 'fetching_orders'
export const RESET_UNCONFIRMED_ORDERS = 'reset_unconfirmed_orders'
export const SET_ACTIVE_ORDERS_STORE = 'set_active_orders_store'

export const START_TOUR = 'start_tour'
export const END_TOUR = 'end_tour'

export const SET_CRUMB = 'set_crumb'
export const RESET_CRUMB = 'reset_crumb'

//DownloadQueue
export const QUEUE_PUSH = 'queue_push'
export const QUEUE_REMOVE = 'queue_remove'

//Favorites Filter
export const TOGGLE_FAVORITE_FILTER = 'toggle_favorite_filter'

//Table State Storage
export const SET_STORED_TABLE_STATE = 'set_stored_table_state'
export const CLEAR_STORED_TABLE_STATE = 'clear_stored_table_state'
export const RESET_STORED_TABLE_STATES = 'reset_stored_table_states'
