// eslint-disable-next-line
import { START_TOUR, END_TOUR } from './types'

import {} from '../actions/types'

//Request UserData
export const startTour = (callback = false) => async dispatch => {
	try {
		//Set Fetching User flag to avoid multiple request
		dispatch({ type: START_TOUR, payload: true })
		if (callback) {
			callback()
		}
	} catch (e) {

	}
}

//Request UserData
export const endTour = (callback = false) => async dispatch => {
	try {
		//Set Fetching User flag to avoid multiple request
		dispatch({ type: START_TOUR, payload: false })
		if (callback) {
			callback()
		}
	} catch (e) {

	}
}
