import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { navBadgeCls, navLinkCls } from "../helpers";
import { fetchRecords } from "../../helpers/Helper";
import { API_URL } from "../../helpers/Constants";

const PriceSchedulerNavIcon = props => {
    const [productQty, setProductQty] = useState(0)

    const fetchProductQty = async () => {
        let options = {
            url: `${API_URL}/admin/price-updates/product-quantity`
        }
        let res = await fetchRecords(options, false)
        if (res !== null) {
            setProductQty(res.data.quantity)
        }
    }

    useEffect(() => {
        fetchProductQty()
            .catch(e => console.log(e))
    });

    return (
        <Link to="/admin/price-scheduler" className={navLinkCls}>
            {productQty > 0 && (
                <span className={navBadgeCls}>
                    {productQty}
                </span>
            )}
            <span className={`fa fa-calendar-alt fa-lg`} />
        </Link>
    )
}

export default PriceSchedulerNavIcon