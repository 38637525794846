import React, { Fragment } from 'react'
import withSizes from 'react-sizes'

//Wrapper component for hoc returning render props

/* export const isMobile = ({ width }) => width < 480
export const isTablet = ({ width }) => width >= 480 && width < 1024
export const isDesktop = ({ width }) => width >= 1024 */
//should change to defaults or Bootstrap based:
/* Extra  <576px	
Small ≥576px	
Medium ≥768px	
Large ≥992px	
Extra large ≥1200px */

//Bootstrap based
const mobileWidth = 576
const desktopWidth = 992

export const isMobile = ({ width }) => width < mobileWidth
export const isTablet = ({ width }) => width >= mobileWidth && width < desktopWidth
export const isDesktop = ({ width }) => width >= desktopWidth


export const mapSizesToProps = sizes => ({
  //backgroundColor: sizes.width > 800 ? 'green' : 'blue',
  isMobile: isMobile(sizes),
  isTablet: isTablet(sizes),
  isDesktop: isDesktop(sizes)
})

/* export const mapSizesToProps = ({ width }) => ({
  //backgroundColor: sizes.width > 800 ? 'green' : 'blue',
  isMobile: width < 576,
  isTablet: width >= 576 && width < 992,
  isDesktop: width >= 992,
}) */

const MediaSizes = ({ isMobile, isTablet, isDesktop, children }) => {

  return <Fragment>{children({ isMobile, isTablet, isDesktop })}</Fragment>
}

export default withSizes(mapSizesToProps)(MediaSizes)