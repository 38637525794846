import * as atatus from 'atatus-js'
import { addNotification as notify } from 'reapop'
//import { getPartners } from './partners'
import {GET_USER, FETCHING_USER, USER_ACTIVE, SET_SAMPLE_REQUEST_COOLDOWN} from './types'
import { setAuth, clearAuth } from './auth'
import { API_URL } from 'helpers/Constants'
import { authApi, getApiHeader } from '../api/api'

const userError = {
	title: 'User Error',
	message: 'Please try to re-login to the application',
	status: 'error',
	position: 'tr',
	dismissible: true,
	dismissAfter: 5000
}

//Request UserData
//add option to get token
export const getUser =
	(callback = false, isToken = false, isStartCheck = false) =>
	async dispatch => {
		const headers = getApiHeader()
		try {
			//Set Fetching User flag to avoid multiple request
			dispatch({ type: FETCHING_USER, payload: true })
			// console.log('Auth Header', getApiHeader());
			//get_token=false
			const tokenParam = isToken ? '&get_token=true' : ''
			const response = await authApi({
				url: `${API_URL}/auth/user?with=partner_group,supplier.vendor${tokenParam}&limit=-1`,
				method: 'get',
				headers
			})
			// console.log('USER', response);

			const { access_token, refresh_token, data } = response.data
			if (isToken) {
				dispatch(setAuth(access_token, refresh_token))
			}
			//potential issue at this dispatch isAuthenticated is true
			//callbacks or resolved promise will be after
			dispatch({ type: GET_USER, payload: data })
			if (callback) {
				callback(true, response)
			}
			if (isStartCheck) {
				//dispatch active user initially - used in public routes to prevent early route changes
				dispatch({ type: USER_ACTIVE, payload: true })
			}
			const { hashid, email, name, role_name } = data
			//Set User In Atatus
			atatus.setUser(hashid, email, name)
			//this should not be needed as auth update will handle
			// get partners
			//let setFirstPartner = ['Operations', 'Developer'].indexOf(role_name) >= 0 ? false : true
			//await dispatch(getPartners(setFirstPartner))
			//Set Fetching User flag to avoid multiple request
			dispatch({ type: FETCHING_USER, payload: false })

			/* if (callback) {
				callback(response)
			} */
			console.log("USER COMPLETE")
			return response
		} catch (e) {
			dispatch(clearAuth(true))
			if (callback) {
				callback(false, e)
			}
			dispatch({ type: FETCHING_USER, payload: false })
			if (!isStartCheck) {
				dispatch(
					notify(userError)
				)
			}
		}
	}

	export const setSampleRequestCooldown = (cooldownStatus) => dispatch => {
		if (cooldownStatus === 1) {
			dispatch({
				type: SET_SAMPLE_REQUEST_COOLDOWN,
				payload: cooldownStatus,
			});
		}
	};

	export const setUserActive = active => dispatch => {
		dispatch({
			type: USER_ACTIVE,
			payload: active
		})
	}
