
import {GOOGLE_TRACKING_ID} from "./Constants";
import ReactGA from "react-ga";

function AnalyticsHelper() {
  function getClient() {
    ReactGA.initialize(GOOGLE_TRACKING_ID);
    return ReactGA;
  }

  return {
    getClient
  };
}


export default AnalyticsHelper