import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as actions from '../../actions'
import 'scss/elements/dropdown-user.scss'
import { Menu, Dropdown } from 'antd'
import { navLinkCls } from '../helpers'
import {subdomainDetails} from "../../utils/subdomain";

class DropdownUser extends Component {

	onLogout = () => {
		this.props.logout(() => {
			//this.props.history.push('/login')
		})
	}

	renderBody = () => {
		const { user, dropdownClassName } = this.props
		return (
			<div className={`ant-dropdown-menu bg-dark ${dropdownClassName} `}>
				<div className={`p-3 pb-0 bg-dark`}>
					{user !== null && (
						<span className="text-white ">
							{user.name} - {user.role_name}
						</span>
					)}
				</div>
				<hr style={{opacity: 0.75}}/>
				<Menu className="bg-dark">
					<Menu.Item
						key={1}
						className="dropdown-item bg-dark text-white px-3"
						onClick={() => {
							this.onLogout()
						}}>
						<i className="fas fa-sign-out-alt me-1" />
						<span>
							<strong>Logout</strong>
						</span>
					</Menu.Item>
				</Menu>
			</div>
		)
	}

	render() {
		const menuBody = this.renderBody()
		const {dropdownClassName} = this.props
		return (
			<Dropdown overlay={menuBody} trigger={['click']} placement="bottomLeft" overlayClassName={dropdownClassName}>
				<a className={navLinkCls}>
					<span className={`fa fa-user-alt fa-lg`} />
				</a>
			</Dropdown>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user.userData
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		actions
	)(DropdownUser)
)
