import { connect } from 'react-redux'
import { compose } from 'redux'
import { selectUserAuthenticated, selectUserAdmin } from '../reducers/user'


//returns wrapped auth connector with additions
//const connector = authenticatedComposer({auth: state.auth.authenticated}, {getUser}, WithSizes)
//const ConnectOne = connector(FirstComponent) ...
//THIS DOES NOT WORK mapState needs to be function
//is Admin?
export function authenticatedComposer(
	//Component,
	mapStateFn, //return additional objects
	mapDispatch = null,
	composer = null
) {
  const mapStateToProps = state => {
    const mapState = mapStateFn ? mapStateFn(state) : {}
    return {
      //auth: state.auth.authenticated,
      userData: state.user.userData,
      isAuthenticated: selectUserAuthenticated(state),
      isAdmin: selectUserAdmin(state),
      //fetchingUser: state.user.fetchingUser,
      userActive: state.user.userActive,
      //partnerContext: state.partnerContext
      ...mapState
    }
  }
  const connector = connect(mapStateToProps, mapDispatch)
	return composer ? compose(connector, composer) : connector
}

// first call: returns a hoc that you can use to wrap any component
/* const connectUser = connect(mapState, mapDispatch)

// second call: returns the wrapper component with mergedProps
// you may use the hoc to enable different components to get the same behavior
const ConnectedUserLogin = connectUser(Login)
const ConnectedUserProfile = connectUser(Profile) */