import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import 'scss/pages/error-page.scss'
import Loadable from 'components/Loadable'
import GridLoader from 'react-spinners/GridLoader'

const ErrorPage = ({ fetchingUser }) => (
	<Loadable loading={fetchingUser} Spinner={GridLoader}>
		<div className="error-page text-center text-danger col-md-12">
			<h1 className="animated fadeIn delay-100 text-danger">404</h1>
			<h3 className="animated fadeIn delay-500 text-danger">Page not found</h3>
			<Link
				to="/"
				replace
				className="btn link-primary btn-link btn-lg btn-block animated fadeIn delay-1000">
				Return to homepage
			</Link>
		</div>
	</Loadable>
)

const mapStateToProps = (state) => {
	return {
		fetchingUser: state.user.fetchingUser
	}
}

export default connect(mapStateToProps)(ErrorPage)
