import React from 'react'

const StagingHazard = () => {
	if (true || process.env.REACT_APP_ENVIRONMENT === 'Production') {
		return null
	} else {
		//let Background = '/Users/fntech/Sites/pp3-subzero/src/hazard-stripe.jpg'
		return (
			<div
				className="background-image"
				style={{
					backgroundImage: `url(/assets/images/hazard-stripe.jpg)`,
					backgroundSize: 'cover',
					height: '25px',
					width: '100%'
				}}
			/>
		)
	}
}

export default StagingHazard
